import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { saveNewMission, onSetLoadingState } from './missionsSlice';
import { onSetPage } from '../filters/filtersSlice'

import { v4 as uuidv4 } from 'uuid';
import MissionEditor from './MissionEditor'


const MissionCreate = () => {

    useEffect(() => {
        dispatch(onSetPage({ page: "missionCreate" }))
    })

    const dispatch = useDispatch()
    const routerHistory = useHistory()

    const mission = {
        id: uuidv4(),
        type: "Mission",
        missionTitle: "",
        missionDescription: "",
        preparationTime: "UPTO30",
        duration: "UPTO30",
        state: "DRAFT",
        sharedWith: [],
        categories: [],
        assets: [],
    };

    const cancelCreation = () => { routerHistory.goBack() }

    const saveEdit = async (data) => {
        //e.preventDefault()
        dispatch(onSetLoadingState({
            loading: true,
            message: "Saving ..."
        }))

        dispatch(saveNewMission(data)).then(() => {
            dispatch(onSetLoadingState({
                loading: false,
                message: "Saved ..."
            }))
            routerHistory.goBack()
        })

    }

    return (
        <>
            <MissionEditor
                pageTitle="Neue Idee?"
                mission={mission}
                cancelCreation={cancelCreation}
                saveEdit={saveEdit}
            />
        </>
    )


}
export default MissionCreate
