import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Divider, Grid, Paper } from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import {
    statusFilterChanged,
    categoryFilterToggled,
    categoryFilterModeSet,
    ratingFilterSet,
    CategoryFilterModes,
    resetFilters
} from './filtersSlice';

import { StatusFilters, RatingFilters } from '../filters/filtersSlice'
import CategoryChips from '../categories/CategoryChips';

const useStyles = makeStyles({
    filterBar: {
        width: '99vw',
        backgroundColor: 'rgba(250,250,250,1)',
        minHeight: '50px',
        margin: '2px 0 1px 0',
        padding: '12px 2px 6px 2px',
    },
    spacer: {
        margin: '8px 0 8px 0',
    },
    chipSpacer: {
        margin: '2px',
    },
});

const Filters = () => {
    const classes = useStyles();

    const dispatch = useDispatch()
    const { filterBarVisible, categoriesFilterMode, ratingFilter, categories, statusFilter, mode } = useSelector((status) => status.filters);

    const onCategoryClicked = (category) => dispatch(categoryFilterToggled({ category: category }))
    const onCategoryFilterModeClicked = (mode) => dispatch(categoryFilterModeSet({ mode: mode }))
    const onRatingFilterClicked = (rating) => dispatch(ratingFilterSet({ rating }))
    const onStatusModeClicked = (mode) => dispatch(statusFilterChanged({ mode: mode }))
    const onResetClicked = () => dispatch(resetFilters())


    const RatingFilterSelector = Object.keys(RatingFilters).map((key) => {
        const value = RatingFilters[key]
        const handleClick = () => onRatingFilterClicked(key)
        const variant = key === ratingFilter ? 'contained' : 'outlined'
        return (
            <Button size="large" key={key} variant={variant} onClick={handleClick}>
                {value}
            </Button>
        )
    })

    const FilterModeSelector = Object.keys(CategoryFilterModes).map((key) => {
        const value = CategoryFilterModes[key]
        const handleClick = () => onCategoryFilterModeClicked(key)
        const variant = key === categoriesFilterMode ? 'contained' : 'outlined'
        return (
            <Button size="small" key={key} variant={variant} onClick={handleClick}>
                {value}
            </Button>
        )
    })

    const StatusFilterSelector = (mode === "edit") ? Object.keys(StatusFilters).map((key) => {
        const value = StatusFilters[key]
        const handleClick = () => onStatusModeClicked(key)
        const variant = key === statusFilter ? 'contained' : 'outlined'
        return (
            <Button size="large" key={key} variant={variant} onClick={handleClick}>
                {value}
            </Button>
        )
    }) : null

    const FilterBar = (filterBarVisible) ? (
        <Paper elevation={8} className={classes.filterBar}>
            <Grid container direction="row" spacing={1} justify="space-around" >
                {mode === "edit" ? (
                    <Grid item xs={4}>
                        <ButtonGroup color="primary" orientation="vertical">
                            {StatusFilterSelector}
                            {RatingFilterSelector}
                        </ButtonGroup>
                    </Grid>
                ) : null}
                <Grid item xs={mode === "edit" ? 8 : 12}>
                    <Grid container mode="column" justify="space-between">
                        <Grid item>
                            <ButtonGroup color="primary"> {FilterModeSelector} </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DeleteSweepIcon/>} onClick={onResetClicked}>Reset</Button>
                        </Grid>
                    </Grid>
                    <Divider className={classes.spacer} />
                    <CategoryChips
                        mode="interactive"
                        selectedCategories={categories}
                        categoryClick={onCategoryClicked}
                    />
                </Grid>
            </Grid>
        </Paper>
    ) : null

    return FilterBar

}

export default Filters 