import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    basicSwipeableListItemContentLeft: {

        boxSizing: "border-box",
        color: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "4px 8px"
      },
      red: {
        backgroundColor: theme.palette.primary.main,
      },
      grey: {
        backgroundColor: "#BCBCBC",
      },
      basicSwipeableListItemContentRight: {

        boxSizing: "border-box",
        color: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "4px 8px",
        justifyContent: "flex-end",
      }

}))

const NullRevealed = ({ label, icon, color="grey", position }) => {
    const classes = useStyles(position)
    const classToUse = "basicSwipeableListItemContent"+position
    return (
        < div className={`${classes[classToUse]} ${classes[color]}` }>
            <h2>{label}</h2>
            <br/>
            {icon}
        </div >
    )
}



export default NullRevealed;