import React, { Component } from 'react'
import { Storage, Cache } from 'aws-amplify'
import Video from './Video';
import Image from './Image'
import { Avatar, Grid } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



class Assets extends Component {

    assetCache = Cache.configure({
        defaultTTL: (15 * 60 * 1000)
    });
    state = {
        isSubscribed: false,
        initialFiles: [],
        selectedCarouselItem: 0
    }

    componentDidMount = () => {
        //Cache.clear()
        this.setState({ isSubscribed: true })
        this.setInitialFiles();
    }
    componentWillUnmount = () => {
        this.setState({ isSubscribed: false })
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.files.length !== prevProps.files.length) {
            this.setInitialFiles();
        }
    }
    setInitialFiles = async () => {
        let myFiles = []
        if (this.props.limit && this.props.files.length > 0) {
            myFiles = [this.props.files[0]]
        } else {
            myFiles = [...this.props.files]
        }
        this.getFiles(myFiles).then((data) => {
            if (this.state.isSubscribed) {
                this.setState({ initialFiles: data })
            }
        })
    }
    getFiles = async (files) => {
        return Promise.all(files.map(async (file) => {
            let cacheKey = "ASS3" + file.path
            let s3Data = Cache.getItem(cacheKey)
            if (!s3Data) {
                s3Data = await Storage.get(file.name)
                Cache.setItem(cacheKey, s3Data);

            }
            return {
                url: s3Data,
                type: file.type,
                name: file.name
            }
        }))
    }

    render() {
        if (this.state.initialFiles.length) {
            if (this.props.carousel) {
                return (
                    <Carousel
                        showThumbs={false}
                        swipeScrollTolerance={100}
                        transitionTime={150}
                        infiniteLoop={true}
                        autoPlay={false}
                        dynamicHeight={true}
                        stopOnHover={true}
                        swipeable={true}
                        interval={3000}

                    >
                        {this.state.initialFiles.map((file, index) => {
                            return (
                                (['image/jpeg', 'image/webp', 'image/png', 'image/gif'].indexOf(file.type) >= 0)
                                    ? <div key={index} >
                                        <img src={file.url} width="100%" alt="" />
                                    </div>
                                    : <div key={index}>
                                        <Video url={file.url} fluid />
                                    </div>
                            )
                        })}

                    </Carousel>
                )
            }
            else if (this.props.limit) {
                if (this.props.avatar) {
                    return (
                        <div>
                            <Avatar style={{ width: this.props.width+"px", height: this.props.height+"px", backgroundColor: "#000000" }}>
                                {this.state.initialFiles.map((file, index) => {
                                    return (
                                        (['image/jpeg', 'image/webp', 'image/png', 'image/gif'].indexOf(file.type) >= 0)
                                            ? <Image onDelete={() => this.props.onDelete(file.name)}{...(this.props.editable)} height={this.props.height} src={file.url} key={index} />
                                            : <Video onDelete={() => this.props.onDelete(file.name)}{...(this.props.editable)} height={100} url={file.url} key={index} />
                                    )
                                })}
                            </Avatar>
                        </div>)
                } else {
                    return (
                        <div>
                            {this.state.initialFiles.map((file, index) => {
                                return (
                                    (['image/jpeg', 'image/webp', 'image/png', 'image/gif'].indexOf(file.type) >= 0)
                                        ? <Image onDelete={() => this.props.onDelete(file.name)}{...(this.props.editable)} width={this.props.width} height={this.props.height} src={file.url} key={index} />
                                        : <Video onDelete={() => this.props.onDelete(file.name)}{...(this.props.editable)} width={this.props.width} height={this.props.height} url={file.url} key={index} />
                                )
                            })}
                        </div>)
                }

            } else {
                return (
                    <div style={{ marginTop: "10px" }}>
                        <Grid container spacing={2} direction="row" justify="space-around">
                            {this.state.initialFiles.map((file, index) => {
                                return (
                                    (['image/jpeg', 'image/webp', 'image/png', 'image/gif'].indexOf(file.type) >= 0)
                                        ? <Grid item key={index}><Image onDelete={() => this.props.onDelete(file.name)} editable={this.props.editable} width={this.props.width} height={this.props.height} src={file.url} key={index} /></Grid>
                                        : <Grid item key={index}><Video onDelete={() => this.props.onDelete(file.name)} editable={this.props.editable} width={this.props.width} height={this.props.height} url={file.url} key={index} /></Grid>
                                )
                            })}
                        </Grid>
                    </div>
                )
            }


        }else {
        return null
    }
    } 
}

export default Assets;