import React from 'react'
import { Button, IconButton, ButtonGroup } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { Whatshot, DeleteForever, ContactSupport, Edit, Visibility, CardGiftcard, Eject, RemoveCircleOutline, AddCircleOutline, Backspace} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => {

    return ({
        alignRight: {
            marginLeft: "auto"

        },
        pulse: {
            animation: `$pulsate 550ms ${theme.transitions.easing.easeOut} 250ms infinite alternate`,
        },
        '@keyframes pulsate': {
            '0%': {
                opacity: "0.6",
                color: theme.palette.primary.dark,
                boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.2)",
            },

            '100%': {
                color: "#CBCBCB",
                opacit: "1",
                boxSshadow: "0 0 0 20px rgba(0, 0, 0, 0)",
            },
        },

    }

    )
})

const MissionActions = (props) => {
    const { pathname } = useLocation()
    const classes = useStyles()
    const mission = props.mission
    const invitation = props.mission.invitation ? props.mission.invitation : {}
    const DeleteButton = (
        <IconButton key="deleteButton" aria-label="delete" onClick={() => { props.confirmDelete(props.mission.id) }}>
            <DeleteForever />
        </IconButton>
    )

    const EditButton = (
        <IconButton key="editButton" aria-label="edit" onClick={() => { props.editClickHandler(props.mission.id) }}>
            <Edit />
        </IconButton>
    )

    const ViewButton = (
        <IconButton key="viewButton" aria-label="view" onClick={() => { props.detailClickHandler(props.mission.id) }}>
            <Visibility />
        </IconButton>
    )
    const RequestButton = (
        <Button key="requestButton" aria-label="request"
            startIcon={<Whatshot />}
            onClick={() => { props.requestRatingClickHandler(props.mission.id) }}>
            Hot?
        </Button>
    )
    const RequestAgainButton = (
        <Button key="requestAgainButton" aria-label="request-again"
            startIcon={<Whatshot />}
            onClick={() => { props.requestRatingClickHandler(props.mission.id) }}>
            Erneut anfragen
        </Button>
    )
    const WishlistButton = (
        <IconButton key="wishlistButton" aria-label="wishlist" alt="Wishlist"
            onClick={() => { props.wishlistClickHandler(props.mission.id) }}>
                <AddCircleOutline/>
        </IconButton>
    )
    const SendInvitationButton = (
        <Button key="invitationButton" aria-label="invitation"
            startIcon={<CardGiftcard />}
            onClick={() => { props.invitationClickHandler(props.mission.invitation, props.mission.id) }}>
            {mission.state === "INVITE" && invitation.state === "PENDING" ? "Offen" 
            : mission.state === "INVITE" &&  invitation.state==="ACCEPTED" ? "Angenommen" 
            : mission.state === "INVITE" && invitation.state==="DECLINED" ? "Abgelehnt" 
            : null } 
        </Button>
    )
    const WishlistRemoveButton = (
        <IconButton key="removeFromWishlistButton" aria-label="remove-from-wishlist"
            onClick={() => { props.wishlistRemoveHandler(props.mission.id) }}>
           <RemoveCircleOutline/>
        </IconButton>
    )


    const RevokeRequestButton = (
        <Button
            variant="text"
            onClick={() => { props.revokeRequestRatingClickHandler(props.mission.id) }}
            startIcon={<ContactSupport color="secondary" className={`${classes.icon} ${classes.pulse}`} />}>
            Zurückziehen
        </Button>)

    const actions = (m) => {
        if (pathname === "/mine" && m.state === "DRAFT") {
            return [
                SendInvitationButton,
                RequestButton,
                EditButton,
                DeleteButton,
                ViewButton,
            ]
        } else if (pathname === "/mine" && m.state === "PUBLISHED") {
            return [
                SendInvitationButton,
                RevokeRequestButton,
                EditButton,
                DeleteButton,
                ViewButton,
            ]

        } else if (pathname === "/wishlist" && m.state === "SHARED") {
            return [
                SendInvitationButton,
                WishlistRemoveButton,
                DeleteButton,
                ViewButton,
                
            ]

        } else if (pathname === "/wishlist" && m.state === "INVITE") {
            return [
                SendInvitationButton,
                WishlistRemoveButton,
                ViewButton,
                
            ]

        } else if (pathname === "/mine" && m.state === "RATED") {
            if (m.rating === "hot") {
                return [
                    SendInvitationButton,
                    WishlistButton,
                    DeleteButton,
                    ViewButton,
                    
                ]
            } else if (m.rating === "not") {
                return [
                    RequestAgainButton,
                    EditButton,
                    DeleteButton,
                    ViewButton,
                ]
            }
        } else if ((pathname === "/inbox" || pathname === "/shared") && m.state === "INVITE") {
            return [
                SendInvitationButton,
                ViewButton,
            ]
        }
        else if (pathname === "/inbox" || pathname === "/shared") {
            return [
                ViewButton,
            ]
        }
    }

    return (
        <>
            <ButtonGroup variant="text" position="right" className={classes.alignRight}>
                {actions(props.mission)}
            </ButtonGroup>
        </>
    )
}


export default MissionActions