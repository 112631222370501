import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { createCategory } from '../../graphql/mutations'

import { API, graphqlOperation } from 'aws-amplify'
import { Button, Container, TextField, Typography } from '@material-ui/core';

class CreateCategory extends Component {
    state = {
        category: []
    }

    cancelCreation = () => {
        this.props.history.goBack();
    }

    saveEdit = async (e) => {
        e.preventDefault()
        const category = {
            categoryName: this.state.category.categoryName
        }
        await API.graphql(graphqlOperation(createCategory, { input: category }));
        this.props.history.goBack();
    }

    categoryNameChanged = (e) => {
        this.setState({
            category: {
                ...this.state.category,
                categoryName: e.target.value
            }
        })
    }

    render() {
        return (
            <Container>
                <Typography variant="h4">Bearbeiten</Typography>
                <form>
                    <TextField
                        id="categoryName"
                        label="Titel"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        rows="2"
                        onChange={this.categoryNameChanged}
                        defaultValue={this.state.category.categoryName}
                    />


                    <Button onClick={this.cancelEdit}>Abbrechen</Button><Button onClick={this.saveEdit}>Änderungen speichern</Button>
                </form>
            </Container>
        )
    }

}
export default withRouter(CreateCategory)