import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Grid, TextField, Typography } from '@material-ui/core';
import Moment from 'react-moment'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/de'


const MyInvitationDetail = (props) => {
    const [invitation, setInvitation] = React.useState(props.invitation);
    useEffect(() => {
        setInvitation({
            ...props.invitation
        })
    }, [props])

    const textChange = (e) => {
        setInvitation(prevState => (
            {
                ...prevState,
                invitationComment: e.target.value
            }
        )

        )
    }
    return (
        <>
            <Dialog
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                open={props.open}
                onClose={props.clickAbortHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                    <form noValidate>
                        <Grid container direction="column">
                            <Grid item>
                                Für <Moment local locale="de" format="dd, DD.MM.YY HH:mm">{props.invitation.startDate}</Moment>
                            </Grid>
                            <Grid item>
                                <Typography variant="p">
                                    {props.invitation.invitationText}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="invitationComment"
                                    label="Wenn du die Einladung annimmst oder ablehnst kannst du auch einen Kommentar dazu abgeben "
                                    multiline
                                    disabled = { invitation.state !== "PENDING" ? true : false}
                                    fullWidth
                                    margin="normal"
                                    rows="5"
                                    variant="outlined"
                                    onChange={(event) => textChange(event)}
                                    defaultValue={invitation.invitationComment}
                                >
                                </TextField>
                            </Grid>
                        </Grid>


                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.clickAbort} color="primary">
                        Abbrechen
          </Button>
                    <Button 
                        disabled = { invitation.state !== "PENDING" ? true : false}
                        onClick={() => { props.clickDecline(invitation) }} color="primary" autoFocus>
                        {props.decline}
                    </Button>
                    <Button 
                        disabled = { invitation.state !== "PENDING" ? true : false}
                        onClick={() => { props.clickConfirm(invitation) }} color="primary" autoFocus>
                        {props.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default MyInvitationDetail