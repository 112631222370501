import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectFilteredMissions } from './missionsSlice'
import { onSetPage } from '../filters/filtersSlice'
import MissionList from './MissionList'
import { Typography } from '@material-ui/core';

const MissionBucket = (props) => {
    const dispatch = useDispatch()
    const missions = useSelector(selectFilteredMissions)
    useEffect(() => {
        dispatch(onSetPage({ page: "missionBucket" }))
    }, [dispatch])


    return (
        <>
            <Typography variant="h4">Deine Sammlung</Typography>
            <Typography variant="subtitle1" paragraph>
                All deine Ideen und Wünsche sind hier!
            </Typography>

            <MissionList
                missions={missions}
            />
        </>
    )
} 



export default (MissionBucket)
