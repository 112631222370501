import { Paper, Typography, Avatar, Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import InboxIcon from '@material-ui/icons/Inbox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FilterListIcon from '@material-ui/icons/FilterList'
import CasinoIcon from '@material-ui/icons/Casino';

import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
    root: {
        width: "98%",
        margin: "0 auto",
        padding: "24px",
    },
    centered: {
        textAlign: "center",
        margin: "0 0 48px 0",
    },
    media: {
        margin: '2px',
    },
});

const Onboarding = () => {
    const { currentUser } = useSelector((status) => status.users);
    const classes = useStyles()

    const paragraphs = [
        {
        title:"Erstellen",
        icon: <AddIcon/>,
        text: "Hier hältst du deine eigenen heißen Ideen fest, die du dann auch mit mir teilen kannst, wenn du möchtest. Nur du hast Zugriff auf alles was du hier festhälst, wenn du es nicht mit mir teilst. Schreibe auf, was dich erregt und heiß macht. Das können Fantasien sein, mit welchen ich dich spontan überraschen kann. Oder auch Spielideen oder kleine Challenges. Um sie einfacher auffindbar und auswählbar zu machen, solltest du sie mit passenden Stichworten versehen. Und da Bilder manchmal mehr als tausend Worte sagen, kannst du natürlich Fotos oder Videos hinzufügen. Ich habe dir bereits ein paar Beispiele angelegt, die dir zum Start vielleicht etwas helfen. Bearbeite oder lösche sie, ganz wie du magst. Du findest sie unter:"
    },
    {
        title:"Meine",
        icon: <ListAltIcon/>,
        text: "Deine gespeicherten Ideen findest du alle hier. Natürlich kannst sie bearbeiten oder löschen. Oder mit mir teilen, sie also für mich sichtbar machen. Auch Kommentare oder Anmerkungen können ergänzt werden oder Fragen, Kommentare oder Anregungen von mir beantwortet werden. Sobald du etwas teilst, kann ich es lesen. Wenn ich meine Ideen mit dir teile siehst du sie ebenfalls unter"
    },
    {
        title:"Unbewertet",
        icon: <InboxIcon/>,
        text: "Hier siehst du also eine meine \"Naughty Little Things\" die ich mit dir teile. In der Hoffnung, dass sie dich genauso heiß machen und etwas dabei ist, womit du mich einfach überraschst, oder was du gerne mit mir gmeinsam planen möchtest. In dieser Ansicht sind alle Ideen gekürzt. Über \"Details ansehen\" kommst du zur ausführlichen Version, die die komplette Beschreibung und alle Bilder und Videos enthält. Um mir etwas zu helfen - und auch um es dir etwas übersichtlicher zu machen - kannst du meine Ideenbewerten. Einfach mit einem \"Hot\", wenn sie dir gefallen oder mit einem \"Not hot\" wenn es nicht nach deinem Geschmack ist."
    },
    {
        title:"Hot!",
        icon: <WhatshotIcon/>,
        text: "Beinhaltet dann also alle Naughty Things, die du als heiß bewertet hast. Genau der richtige Platz, um nach möglichen Überraschungen zu stöbern. Alles was du nicht heiß fandest findest du ementsprechend unter"
    },
    {
        title:"Not hot",
        icon: <ThumbDownIcon/>,
        text: "Wenn du möchtest, kannst du mir mit einem Kommentar helfen, wenn dir nur Kleinigkeiten nich gefallen, oder dir etwas unklar ist. Alles was dir nicht gefällt, werde ich vermutlich nach und nach löschen."
    },
    {
        title:"Filter",
        icon: <FilterListIcon/>,
        text: "Die Filter sollen dir in den jeweiligen Anischten helfen, etwas bestimmtes zu finden."
    },
    {
        title:"Shuffle",
        icon: <CasinoIcon/>,
        text: " Damit kannst du dir in den einzelnen Ansichten eine zufälliges Naughty Little Thing anzeigen lassen. Alle Filtereinstellungen sind dabei aktiv. Wenn du dich also selbst überraschen möchtest... ;-)"
    },

    ]

    const helperText = paragraphs.map((p,i) => (
        <Grid
            key={i}
            className={classes.media}
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="flex-start"
        >
            <Grid item xs={2}>
                <Avatar>{p.icon}</Avatar>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="h6">{p.title}</Typography>
                <Typography>{p.text}</Typography>
            </Grid>
        </Grid>
    ))

    return (
        <>
            <Paper className={classes.root}>
                <div className={classes.centered}>
                    <Typography variant="h3">
                        <img src="/logo192.png" width="192" height="192" className={classes.media} alt="Naughty Little Things"/>
                        <br />
            Willkommen, {currentUser}!
            </Typography>
                    <Typography variant="subtitle1">
                        Naughty Little Things ist eine Sammlung all unserer heißen Ideen, die wir hier miteinander teilen können
                        und uns so immer wieder etwas aussuchen können womit wir uns gegenseitig überraschen können. Ganz spontan
                        beim Sofaabend oder auch gemeinsam geplant. Also einfach immer mal wieder 
                        reinschauen wenn du in Stimmung bist oder wenn du dich in Stimmung bringen möchtest ;-)
                        
            </Typography>


                </div>
                <Typography variant="h5">
                    Ich werde dir kurz erklären, wie es funktioniert
            </Typography>
            {helperText}
            </Paper>
        </>
    )
}
export default Onboarding