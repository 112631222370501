import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAllCategories, saveNewCategory } from './categoriesSlice';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Chip, TextField, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
    root: {
        margin: "2px 0 8px 0",
    },
    chipsSpacer: {
        margin: "1px",
    }

})

const CategoryChips = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [addCategory, setAddCategory] = useState(false)
    const [newCategory, setNewCategory] = useState("")
    const allCategories = useSelector(selectAllCategories)

    const newCategoryChangeHandler = (e) => {
        setNewCategory(e.target.value)
    }
    const newCategorySaveHandler = () => {
        dispatch(saveNewCategory(newCategory)).then(() => {
            setNewCategory("")
            setAddCategory(false)
        })

    }
    const newCategoryAbortHandler = () => { setAddCategory(false) }



    const chips = (props.mode === "interactive") ? allCategories.map((category, i) => {
        return (
            <Chip
                className={classes.chipsSpacer}
                size="small"
                label={category.categoryName}
                color="primary"
                onClick={() => props.categoryClick(category.categoryName)}
                variant={
                    props.selectedCategories.find((c) => c === category.categoryName)
                        ? "default"
                        : "outlined"
                }
                key={i} />
        )
    })
        : props.categories.map((category, i) => {
            return (
                <Chip
                    className={classes.chipsSpacer}
                    size="small"
                    label={category}
                    color="primary"
                    variant="default"
                    key={i} />
            )
        })

    const addNewCategory = addCategory ? (
        <Grid container direction="row" alignItems="center">
            <Grid item>
                <TextField
                margin="dense"
                autoFocus
                    id="newCategory"
                    label="Neue Kategorie"
                    variant="outlined"
                    onChange={newCategoryChangeHandler}
                    value={newCategory}
                />
            </Grid>
            <Grid item><IconButton onClick={newCategorySaveHandler}>
                <SaveIcon fontSize="large" />
            </IconButton>
            </Grid>
            <Grid item><IconButton onClick={newCategoryAbortHandler}>
                <CancelIcon fontSize="large" />
            </IconButton>
            </Grid></Grid>
    ) : <IconButton size="small" onClick={() => setAddCategory(true)}>
            <AddIcon />
        </IconButton>

    return (
        <div className={classes.root}>{chips}{props.allowNew ? addNewCategory : null}</div>
    )
}
export default CategoryChips