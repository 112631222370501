import { createSlice,  createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { listCategorys } from '../../graphql/queries'
import { createCategory } from '../../graphql/mutations'

const categoriesAdapter = createEntityAdapter()

const initialState = categoriesAdapter.getInitialState({

})

export const fetchCategories = createAsyncThunk(
    'categories/fetch',
    async (_, { rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(listCategorys));
            return result.data.listCategorys.items
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const saveNewCategory = createAsyncThunk(
    'categories/new',
    async (category, { rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(createCategory, {
                input: {
                   categoryName: category
                }
            }));
            return result.data.createCategory
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
            categoriesAdapter.setAll(state, payload)
        })
        builder.addCase(fetchCategories.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        builder.addCase(saveNewCategory.fulfilled, (state, { payload }) => {
            categoriesAdapter.addOne(state, payload)
        })
        builder.addCase(saveNewCategory.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
    },
})


export const {
    selectById: selectCategoryById,
    selectIds: selectCategoryIds,
    selectEntities: selectCategoryEntities,
    selectAll: selectAllCategories,
    selectTotal: selectTotalCategories
} = categoriesAdapter.getSelectors(state => state.categories)

export default categoriesSlice.reducer


