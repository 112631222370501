import { createSlice, createSelector } from '@reduxjs/toolkit'

export const StatusFilters = {

}
export const RatingFilters = {
    draft: 'Entwürfe',
    none: 'Angefragt',
    hot: 'Hot!',
    not: 'Not hot',
}

export const CategoryFilterModes = {
    OR: 'ODER',
    AND: 'UND',
    NOR: 'NICHT',
}

const initialState = {
    user: null,
    mode: "view",
    page: "missionList",
    statusFilter: "ALL",
    ratingFilter: null,
    filterBarVisible: false,
    categories: [],
    categoriesFilterMode: "OR",
    activeFilterCount: 0,
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        statusFilterChanged(state, action) {
            state.statusFilter = (state.statusFilter === action.payload.mode) ? null : action.payload.mode
        },
        categoryFilterToggled: {
            reducer(state, action) {
                const { category } = action.payload
                const { categories } = state
                if (!categories.includes(category)) {
                    categories.push(category)
                }
                else {
                    state.categories = categories.filter(
                        (existingCategory) => existingCategory !== category
                    )
                }
            }
        },
        resetFilters(state, action) {
            state.categoriesFilterMode = "OR"
            state.categories = []
            state.ratingFilter = null
            state.statusFilter = "ALL"


        },
        categoryFilterModeSet(state, action) {
            state.categoriesFilterMode = action.payload.mode
        },
        toggleFilterVisibility(state, action) {
            (action.payload === false || action.payload === true) ? state.filterBarVisible = action.payload : state.filterBarVisible = !state.filterBarVisible
        },
        ratingFilterSet(state, action) {
            state.ratingFilter = (state.ratingFilter === action.payload.rating) ? null : action.payload.rating

        },
        modeSet(status, action) {
            status.mode = action.payload.mode
        },
        userSet(status, action) {
            status.user = action.payload
        },
        onSetPage(state, action) {
            state.page = action.payload.page
        },

    },
})

export const {
    colorFilterChanged,
    statusFilterChanged,
    categoryFilterToggled,
    categoryFilterModeSet,
    ratingFilterSet,
    toggleFilterVisibility,
    userSet,
    modeSet,
    onSetPage,
    resetFilters,
} = filtersSlice.actions

export default filtersSlice.reducer

export const isPlayable = createSelector(
    (state) => state.filters,
    (filters) => {
        return (filters.page !== "missionEdit" && filters.page !== "missionCreate" && filters.mode !== "edit")
    }
)
export const isFilterable = createSelector(
    (state) => state.filters,
    (filters) => {
        return (filters.page !== "missionCreate" && filters.page !== "missionEdit")
    }
)
export const getActiveFiltersCount = createSelector(
    (state) => state.filters,
    (filters) => {
        return (
            filters.categories.length
            + ((filters.mode==="edit" && (filters.statusFilter && filters.statusFilter !== "ALL")) ? 1 : 0)
            + ((filters.mode==="edit" && filters.ratingFilter) ? 1 : 0)
        )
    }
)