import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de'
import { Card, CardContent, CardHeader, Typography, Button, Grid, CardActions } from '@material-ui/core'
import CategoryChips from '../categories/CategoryChips'
import Assets from '../../Assets/Assets'
import Truncate from 'react-truncate';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MissionRating from './MissionRating';
import MissionShare from './MissionShare';
import Comments from '../comments/Comments';
import MissionActions from './MissionActions';
import MissionRatingBadge from './MissionRatingBadge';
import MissionWishListTimer from './missionWishlistTimer';


const useStyles = makeStyles((theme) => ({
    missionDescription: { whiteSpace: "pre-line" },
    missionDescriptionCompact: { whiteSpace: "nowrap" },
    missionCard: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        width: "97%",
    },

    hot: {

    },
    not: {
        backgroundColor: "#EFEFEF",
        opacity: "0.5",
    },
    spacer: {
        margin: "8px 0 0 0",
    }
}))

const MissionCard = (props) => {
    const classes = useStyles()
    const mission = props.mission

    const missionDateString = (
        <><Moment format="DD.MM.YYYY HH:mm">{mission.createdAt}</Moment> (Update <Moment fromNow locale="de">{mission.updatedAt}</Moment>)</>
    )

    return (

        <Card key={mission.id} className={`${classes.missionCard} ${classes[mission.rating]} `}>
            <MissionRatingBadge mission={mission} />
            <MissionWishListTimer
                    startDate={mission.shareStart}
                    endDate={mission.shareEnd}
                    state={mission.state}
                />

            <CardHeader title={mission.missionTitle} subheader={missionDateString} onClick={() => props.detailClickHandler(mission.id)} />
            <CategoryChips categories={mission.categories} />
            <div onClick={() => props.detailClickHandler(mission.id)}>
                <Assets limit files={mission.assets} width="100%" height={200} ></Assets>
            </div>
            <CardContent className={classes.cardContent}>
                <MissionRating mode={props.mode} missionId={mission.id} rating={mission.rating} />
                <div onClick={() => props.detailClickHandler(mission.id)}>
                    <Typography display="block"
                        className={classes.missionDescriptionCompact}
                        paragraph
                    >
                        <Truncate lines={8}>
                            {mission.missionDescription}
                        </Truncate>
                    </Typography>

                    <Comments
                        onlyLast
                        missionId={mission.id}
                    />
                </div>
            </CardContent>
            <CardActions>
                <MissionActions {...props} />
            </CardActions>
        </Card>
    )

}

export default MissionCard