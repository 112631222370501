import MyAppBar from './MyAppBar';
import React from 'react';
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import InboxIcon from '@material-ui/icons/Inbox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Container, Backdrop, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Button, Box } from '@material-ui/core';
import { isPlayable, isFilterable, getActiveFiltersCount } from '../../features/filters/filtersSlice'
import { selectFilteredMissions } from '../../features/missions/missionsSlice'

import FilterBar from './FilterBar'
import Shuffle from './Shuffle';
import MyBottomNavigationAction from './MyBottomNavigationAction';
import MyBottomNavigationBar from './MyBottomNavigationBar';


const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    root: {
        backgroundColor: "#FFFFFF",
        color: "#666666",
        position: "relative",

        width: "100%",
        minHeight: "90vh",

        overflow: "hidden",
        marginBottom: "90px"

    },
    contentBox: {
        padding: "8px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',

    },

    fullList: {
        width: 'auto',
    },

    bottomNav: {
        position: 'fixed',
        width: '99vw',
        bottom: '0px',

    },
    addButton: {
        position: "absolute",
        bottom: "100px",
        right: "40px"

    },
    nothingToSeeHere: {
        height: "200px"
    },
    versionTag: {
        position: "fixed",
        bottom: "10px",
        width: "200px",
        textAlign: "center"

    }
}));

const Layout = (props) => {
    const routerHistory = useHistory()
    const classes = useStyles();
    const { loadingState, page } = useSelector((state) => state.missions)
    const { mode } = useSelector((state) => state.filters)
    const activeFilterCount = useSelector(getActiveFiltersCount)
    const missionCount = {
        hot: useSelector((state) => selectFilteredMissions(state, "viewHot")).length,
        notHot: useSelector((state) => selectFilteredMissions(state, "viewNotHot")).length,
        view: useSelector((state) => selectFilteredMissions(state, "view")).length,
        edit: useSelector((state) => selectFilteredMissions(state, "edit")).length,
        wishlist: useSelector((state) => selectFilteredMissions(state, "wishlist")).length,

    }
    const playable = useSelector(isPlayable)
    const filterable = useSelector(isFilterable)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const reloadApp = () => {
        routerHistory.push("/")
        window.location.reload()
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button key="Hilfe" onClick={() => { routerHistory.push("/intro") }}>
                    <ListItemIcon><HelpIcon /></ListItemIcon>
                    <ListItemText primary="Hilfe" />
                </ListItem>

            </List>
            <div className={classes.versionTag}>
                <Button onClick={reloadApp}><RefreshIcon /></Button>
                Version 0.3.10
                </div>
        </div>
    );

    return (

        <CssBaseline >
            <div className={classes.root}>
                <MyAppBar username={props.username} burgerClick={toggleDrawer('left', true)}></MyAppBar>
                <Box className={classes.contentBox}>
                    {props.children}
                    <Container className={classes.nothingToSeeHere}><p></p></Container>
                </Box>
                <div className={classes.bottomNav}>
                    <Shuffle visible={playable} />
                    <FilterBar
                        visible={filterable}
                        filterCount={activeFilterCount}
                        filterBarVisible={props.filterBarVisible}
                        filterToggleClick={props.filterToggleClick}
                    />

                    <MyBottomNavigationBar>
                        <MyBottomNavigationAction
                            onClick={props.showRemainingClick}
                            mode={mode}
                            target="view"
                            label="Inbox"
                            icon={<InboxIcon />}
                            count={missionCount.view}
                        />
                        <MyBottomNavigationAction
                            onClick={props.showSharedClick}
                            mode={mode}
                            target="viewHot"
                            label="Spielwiese"
                            icon={<WhatshotIcon />}
                            count={missionCount.hot}
                        />

                        <Divider orientation="vertical" />

                        <MyBottomNavigationAction
                            onClick={props.showEditableClick}
                            mode={mode}
                            target="edit"
                            label="Sammlung"
                            icon={<ListAltIcon />}
                            count={missionCount.edit}
                        />
                        <MyBottomNavigationAction
                            onClick={props.showWishlistClick}
                            mode={mode}
                            target="wishlist"
                            label="Wishlist"
                            icon={<WhatshotIcon />}
                            count={missionCount.wishlist}
                        />
                        <MyBottomNavigationAction
                            onClick={props.createNewMissionClick}
                            mode={page}
                            target="missionCreate"
                            label="Neu"
                            icon={<AddIcon />}
                            count={0}
                        />


                    </MyBottomNavigationBar>

                </div>

                <SwipeableDrawer
                    anchor='left'
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    {list('left')}
                </SwipeableDrawer>
            </div>
            <Backdrop className={classes.backdrop} open={loadingState.loading}>
                <CircularProgress color="inherit" />
                {loadingState.message}
            </Backdrop>

        </CssBaseline>

    )
}

export default Layout;