import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import 'moment/locale/de'
import { useSelector, useDispatch } from 'react-redux'
import { addMissionComment, selectSortedComments } from '../missions/missionsSlice'
import { Typography, Divider, IconButton, Grid, TextField } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    spacer: {
        margin: "8px 0 0 0",
    },

}))
const Comments = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [addComment, setAddComment] = useState(false)
    const [newComment, setNewComment] = useState("")
    const comments = useSelector((state) => selectSortedComments(state, props.missionId));

    const newCommentChangeHandler = (e) => {
        setNewComment(e.target.value)
    }
    const newCommentSaveHandler = () => {
        dispatch(addMissionComment({ commentMissionId: props.missionId, content: newComment }))
            .then(() => {
                setNewComment("")
                setAddComment(false)
            })

    }
    const newCommentAbortHandler = () => { setAddComment(false) }
    const myComments =  (props.onlyLast) ? comments.slice(-1) : comments
    const commentsList = myComments.map((comment) => {
        return (
            <div key={comment.id}>
                <Divider className={classes.spacer} />
                <Typography variant="caption">{comment.owner}, <Moment locale="de" fromNow>{comment.createdAt}</Moment></Typography>
                <Typography variant="body2">{comment.content}</Typography>

            </div>
        )
    })
 
    if (myComments.length > 0 || props.allow) {
        return (
            <>
                <Typography variant="subtitle2" className={classes.spacer}>{props.onlyLast ? (
                    <>Neuester Kommentar</>
                ) : (
                    <>Fragen, Anregungen, Kommentare?</>
                )}
                </Typography>
                {commentsList}
                <div className={classes.spacer}></div>
                {addComment ? (
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                margin="dense"
                                fullWidth
                                autoFocus
                                multiline
                                rows={3}
                                id="newComment"
                                label="Kommentar"
                                variant="outlined"
                                onChange={newCommentChangeHandler}
                                value={newComment}
                            />
                        </Grid>
                        <Grid item><IconButton onClick={newCommentSaveHandler}>
                            <SendIcon fontSize="large" />
                        </IconButton>
                        </Grid>
                        <Grid item><IconButton onClick={newCommentAbortHandler}>
                            <CancelIcon fontSize="large" />
                        </IconButton>
                        </Grid></Grid>
                ) : null 
                }
                {!props.onlyLast && !addComment? (
                    <IconButton size="small" onClick={() => setAddComment(true)}>
                            <CommentIcon /> Kommentieren
                        </IconButton>
                ): null}
            </>
        )
    } else {
        return null
    }
}
export default Comments