import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/de'
import { TextField } from '@material-ui/core';


const MyInvitation = (props) => {
    const [startDate, setStartDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm"));
    const [invitation, setInvitation] = React.useState({ ...props.invitation });
    useEffect(() => {
        setInvitation({
            ...props.invitation,
            startDate: (props.startDate) ? props.startDate : new Date().toISOString(),
           state: (props.invitation.state) ?  props.invitation.state : ""
        })
    }, [props])

    const handleStartDateChange = (date) => {
        setInvitation({
            ...invitation,
            startDate: date

        });
    };
    const textChange = (e) => {
        setInvitation(prevState => (
            {
                ...prevState,
                invitationText: e.target.value
            }
        )

        )
    }
    return (
        <div>
            
            <Dialog
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                open={props.open}
                onClose={props.clickAbortHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                    <form noValidate>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <Grid container direction="column">
                                <Grid item>
                                    <DateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start"
                                        disabled = { invitation.state !== "" ? true : false}
                                        value={invitation.startDate}
                                        ampm={false}
                                        onChange={handleStartDateChange}

                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="invitationText"
                                        label="Text für deine persönliche Einladung"
                                        multiline
                                        disabled = { invitation.state !== "" ? true : false}
                                        fullWidth
                                        margin="normal"
                                        rows="5"
                                        variant="outlined"
                                        onChange={(event) => textChange(event)}
                                        defaultValue={invitation.invitationText}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>


                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.clickAbort} color="primary">
                        Abbrechen
          </Button>
                    <Button
                    disabled = { invitation.state !== "PENDING" ? true : false}
                    onClick={() => { props.clickRevoke(invitation) }} color="primary" autoFocus>
                        {props.revokeAction}
                    </Button>
                    <Button 
                        disabled = { invitation.state !== "" ? true : false}
                        onClick={() => { props.clickConfirm(invitation) }} color="primary" autoFocus>
                        {props.action}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default MyInvitation