import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import theme from './theme'
import { Auth } from 'aws-amplify';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const signOut = () => {
  console.log('signOut')
  Auth.signOut();
} 

export default function MyAppBar(props) {
  const classes = useStyles(theme);

  return (
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" onClick={props.burgerClick} className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            NaughtyLittleThings
          </Typography>
          <Button color="inherit" onClick={signOut}>{props.username} abmelden</Button>

        </Toolbar>
      </AppBar>
   
  );
}
