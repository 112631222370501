import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import Layout from '../components/Layout/Layout'
import Onboarding from './Onboarding'
import Amplify from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation } from 'aws-amplify'
import {
  onDeleteMission as graphOnDeleteMission,
  onUpdateMission as graphOnUpdateMission,
  onCreateMission as graphOnCreateMission,
  onCreateComment as graphOnCreateComment

} from '../graphql/subscriptions'
import { Route, Switch, useHistory } from 'react-router-dom'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import MissionInbox from '../features/missions/MissionInbox'

import awsconfig from '../aws-exports';
import CreateCategory from '../container/Category/CreateCategory'
import { useDispatch, useSelector } from 'react-redux'
import { modeSet, userSet, toggleFilterVisibility } from '../features/filters/filtersSlice'
import { onUserSet } from '../features/users/usersSlice'
import { fetchMissions, onUpdateMission, onNewMission, onDeleteMission, onInsertComment } from '../features/missions/missionsSlice'
import { fetchCategories } from '../features/categories/categoriesSlice'
import MissionEdit from '../features/missions/MissionEdit'
import MissionCreate from '../features/missions//MissionCreate'
import MissionDetail from '../features/missions//MissionDetail';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingDialog from './OnBoardingDialog';
import MissionShared from '../features/missions/MissionShared';
import MissionBucket from '../features/missions/MissionBucket';
import MissionWishlist from '../features/missions/MissionWishlist';
import InvitationAction from '../features/missions/InvitationAction'
import LogRocket from 'logrocket';
// LogRocket.init('xnnop3/mip');

const useStyles = makeStyles({
  modalStyle: {
    position: 'absolute',
    top: '20%',
    left: '10%',
    overflow: 'scroll',
    height: '70vh',
    margin: "50px 10px 0 10px",
    display: 'block'
  },


});


// ...
const mapObj = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = value => [`${value}`];
const mapToArrayOfStrings = mapObj(toArrayOfStrings);

async function trackUserId() {
  console.log('Update Endpoint')
  try {
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user
    const userAttributes = mapToArrayOfStrings({ ...attributes, username: user.username });
    console.log(user, userAttributes)
    Analytics.updateEndpoint({
      address: attributes.email,
      username: user.username,
      channelType: 'EMAIL',
      optOut: 'NONE',
      userId: attributes.sub,
      userAttributes,
    });
  } catch (error) {
    console.log(error);
  }
}
Amplify.configure(awsconfig);

const App = () => {
  const classes = useStyles()
  const routerHistory = useHistory()
  const dispatch = useDispatch()
  const { filterBarVisible, activeFilterCount, user, mode } = useSelector((status) => status.filters);
  const [authState, setAuthState] = useState();
  const onShowHotClicked = () => { routerHistory.push('/'); dispatch(modeSet({ mode: 'viewHot' })) }
  const onShowNotHotClicked = () => { routerHistory.push('/'); dispatch(modeSet({ mode: 'viewNotHot' })) }
  const onShowRemainingClicked = () => { routerHistory.push('/inbox'); dispatch(modeSet({ mode: 'view' })) }
  const onShowSharedClicked = () => { routerHistory.push('/shared'); dispatch(modeSet({ mode: 'viewHot' })) }
  const onShowEditableClicked = () => { routerHistory.push('/mine'); dispatch(modeSet({ mode: 'edit' })) }
  const onShowWishlistClicked = () => { routerHistory.push('/wishlist'); dispatch(modeSet({ mode: 'wishlist' })) }
  const onFilterToggled = () => { dispatch(toggleFilterVisibility()) }
  const onCreateNewMissionClicked = () => { routerHistory.push("/mission/create"); dispatch(toggleFilterVisibility(false)) }

  useEffect(() => {
    dispatch(fetchMissions())
    dispatch(fetchCategories())
    const createMissionListener = API.graphql(graphqlOperation(graphOnCreateMission, {}))
      .subscribe({
        next: missionData => {
          dispatch(onNewMission(missionData.value.data.onCreateMission))
        }
      }, {})
    const updateMissionListener = API.graphql(graphqlOperation(graphOnUpdateMission, {}))
      .subscribe({
        next: missionData => {
          dispatch(onUpdateMission(missionData.value.data.onUpdateMission))
        }
      }, {})
    const deleteMissionListener = API.graphql(graphqlOperation(graphOnDeleteMission, {}))
      .subscribe({
        next: missionData => {
          dispatch(onDeleteMission(missionData.value.data.onDeleteMission))
        }
      }, {})
    const createCommentListener = API.graphql(graphqlOperation(graphOnCreateComment, {}))
      .subscribe({
        next: missionData => {
          dispatch(onInsertComment(missionData.value.data.onCreateComment))
        }
      }, {})

    return () => {
      createMissionListener.unsubscribe()
      updateMissionListener.unsubscribe()
      deleteMissionListener.unsubscribe()
      createCommentListener.unsubscribe()
    }
  }, [dispatch, user]);

  useEffect(() => {
    trackUserId();
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      if (authData) {
        dispatch(userSet(authData.username))
        dispatch(onUserSet(authData.username))


      }
    });
  }, [dispatch]);



  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active:', getLastActiveTime())
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time last active', getLastActiveTime())
  }

  const handleOnAction = (e) => {
    console.log('user did something', e)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 0.5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,

    debounce: 1000,
  })

  return authState === AuthState.SignedIn && user ? (
    <div className={classes.App}>

      <Layout
        filterBarVisible={filterBarVisible}
        filterCount={activeFilterCount}
        username={user}
        mode={mode}
        showHotClick={onShowHotClicked}
        showNotHotClick={onShowNotHotClicked}
        showRemainingClick={onShowRemainingClicked}
        showSharedClick={onShowSharedClicked}
        showEditableClick={onShowEditableClicked}
        filterToggleClick={onFilterToggled}
        createNewMissionClick={onCreateNewMissionClicked}
        showWishlistClick={onShowWishlistClicked}
      >

        <Switch>
          <Route path="/intro" exact component={Onboarding} />
          <Route path="/" exact>
            <MissionInbox />
          </Route>
          <Route path="/inbox" exact component={MissionInbox} />
          <Route path="/hot" exact component={MissionInbox} />
          <Route path="/shared" exact component={MissionShared} />
          <Route path="/mine" exact component={MissionBucket} />
          <Route path="/wishlist" exact component={MissionWishlist} />



          <Route path="/mission/:id/edit" exact component={MissionEdit} />
          <Route path="/mission/:id/detail" exact component={MissionDetail} />

          <Route path="/invitation/:id/accept" exact>
            <InvitationAction action="accept" />
          </Route>
          <Route path="/invitation/:id/decline  " exact>
            <InvitationAction action="decline" />
          </Route>
          <Route path="/mission/create" exact component={MissionCreate} />

          <Route path="/category/create/" exact component={CreateCategory} />


        </Switch>

      </Layout>
    </div>
  ) : (
      <AmplifyAuthenticator>
        <AmplifySignIn
          slot="sign-in"
          formFields={[
            { type: "username" },
            { type: "password" },

          ]}
          hideSignUp={true}
        />
      </AmplifyAuthenticator>

    );
}

export default App;