import { IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { rateMission } from './missionsSlice'
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';



const MissionRating = (props) => {
    const dispatch = useDispatch()
    const rate = (what) => {
        if (props.mode !== "edit") {
            dispatch(rateMission({ id: props.missionId, rating: what }))
        }
    }

    return (
        <div align="right">
            {props.mode !== "edit" ? (<Typography variant="subtitle2">Deine Bewertung</Typography>) : null}
            <IconButton{...(props.rating === "hot" ? { color: 'secondary' } : {})}
                onClick={() => rate('hot')}
            >
                <WhatshotIcon />
                <Typography>Hot?</Typography>
            </IconButton>

            <IconButton{...(props.rating === "not" ? { color: 'secondary' } : {})}
                onClick={() => rate('not')}
            >
                <ThumbDownIcon />
                <Typography>or not?</Typography>
            </IconButton>
        </div>
    )
}
export default MissionRating