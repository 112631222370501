import { Fab } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CasinoIcon from '@material-ui/icons/Casino';
import { useHistory } from "react-router-dom";

import {  onSetLoadingState, selectFilteredMissions } from '../../features/missions/missionsSlice'

const Shuffle = (props) => {
    const dispatch = useDispatch()
    const routerHistory = useHistory()
    const filteredMissions = useSelector(selectFilteredMissions)

    const shuffle = () => {
        if (filteredMissions.length > 0) {
            dispatch(onSetLoadingState({
                loading: true,
                message: "Ich würfle ..."
            }))
            const randomMission = filteredMissions[Math.floor(Math.random() * filteredMissions.length)];

            setTimeout(() => {
                dispatch(onSetLoadingState({
                    loading: false,
                    message: "..."
                }))
                routerHistory.push("/mission/" + randomMission.id + "/detail")
            }, 1000 + Math.random() * 3000)
        }
    }
    if (props.visible) {
        return (

            <Fab onClick={shuffle}>
                <CasinoIcon/>
            </Fab>
        )


    } else {
        return null
    }

}

export default Shuffle