import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {
    selectReloadTimer,
    setReloadTimer,
    selectFilteredMissions,
    shareMissionWith,
    removeMission,
    requestRating,
    revokeRequestRating,
    rateMission,
    putOnWishlist,
    removeFromWishlist,
    sendInvitation
} from './missionsSlice'
import { getListMode, selectUsersToShareWith } from '../users/usersSlice'
import {
    SwipeableList,
    SwipeableListItem
} from '@sandstreamdev/react-swipeable-list';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RedeemIcon from '@material-ui/icons/Redeem';
import {
    ActionAnimations,
} from '@sandstreamdev/react-swipeable-list';
import NullRevealed from '../../Assets/NullRevealed'
import LazyLoad from 'react-lazyload'
import MissionCard from './MissionCard'
import MissionListItem from './MissionListItem'
import MissionListModeSwitcher from './MissionListModeSwitcher'
import AlertDialog from '../../Helpers/AlertDialog'
import './swipeable.css';
import MyDateTimePicker from './MyDateTimePicker';
import moment from 'moment'
import NoContent from './NoContent';
import MyInvitation from './MyInvitation';
import MyInvitationDetail from './MyInvitationDetail';

const MissionList = (props) => {
    const listMode = useSelector(getListMode)
    const dispatch = useDispatch()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [wishlistOpen, setWishlistOpen] = useState(false)
    const [invitationOpen, setInvitationOpen] = useState(false)
    const [invitationDetailOpen, setInvitationDetailOpen] = useState(false)
    const [wishListMissionId, setWishlistMissionId] = useState()
    const [invitation, setInvitation] = useState({})
    const [deleteMissionId, setDeleteMissionId] = useState()
    const routerHistory = useHistory();
    const missions = useSelector(selectFilteredMissions)
    const reloadTimer = useSelector(selectReloadTimer)
    const { mode, activeFilterCount } = useSelector((state) => state.filters)
    const usersToShareWith = useSelector(selectUsersToShareWith)

    useEffect(() => {
        const rerender = setInterval(() => {
            dispatch(setReloadTimer(new Date().valueOf()))
        }, 60000)

        return () => {
            clearInterval(rerender)
        }
    }, [reloadTimer, dispatch])

    const shareMission = (id, shareWith) => {
        dispatch(shareMissionWith(id, shareWith))
    }
    const confirmDelete = (id) => {
        setDialogOpen(true)
        setDeleteMissionId(id)
    }

    const deleteMissionConfirmHandler = async () => {
        dispatch(removeMission(deleteMissionId))
        setDialogOpen(false)
    }

    const deleteMissionAbortHandler = () => {
        setDialogOpen(false)
        setDeleteMissionId()

    }
    const editMission = (id) => {
        routerHistory.push('/mission/' + id + "/edit")
    }
    const viewDetailMission = (id) => {
        routerHistory.push('/mission/' + id + "/detail")
    }
    const requestMissionRating = (id) => {
        const user = usersToShareWith[0]
        dispatch(requestRating({ id: id, user: user }))
    }
    const revokeRequestMissionRating = (id) => {
        dispatch(revokeRequestRating({ id: id }))
    }
    const putOnWishlistClick = (id) => {
        setWishlistMissionId(id)
        setWishlistOpen(true)
    }
    const removeFromWishlistClick = (id) => {
        dispatch(removeFromWishlist({ id: id }))
    }

    const invitationClick = (invitation, id) => {
        setWishlistMissionId(id)
        setInvitation({
            ...invitation,
            invitationMissionId: id
        })
        if (mode === "view" || mode === "viewHot") {
            setInvitationDetailOpen(true)
        } else {
            setInvitationOpen(true)
        }
    }
    const newInvitationConfirmHandler = (payload) => {
        const shareWith = usersToShareWith[0]
        dispatch(sendInvitation({
            ...payload,
            shareWith,
            state:"PENDING",
            shareWith: shareWith,
            invitationMissionId: wishListMissionId
        }))
        setWishlistMissionId()
        setInvitationOpen(false)
        setInvitationDetailOpen(false)

    }
    const invitationConfirmHandler = (payload) => {
        dispatch(sendInvitation({
            ...payload,
            invitationMissionId: wishListMissionId,
            state: "ACCEPTED",

        }))

        setWishlistMissionId()
        setInvitationOpen(false)
        setInvitationDetailOpen(false)

    }
    const invitationDeclineHandler = (payload) => {
        dispatch(sendInvitation({
            ...payload,
            invitationMissionId: wishListMissionId,
            state: "DECLINED"
        }))
        setWishlistMissionId()
        setInvitationOpen(false)
        setInvitationDetailOpen(false)

    }
    const invitationRevokeHandler = (payload) => {
        dispatch(sendInvitation({
            ...payload,
            invitationMissionId: wishListMissionId,
            state: "REVOKED"
        }))
        setWishlistMissionId()
        setInvitationOpen(false)
        setInvitationDetailOpen(false)

    }
    const invitationAbortHandler = (id) => {
        setWishlistMissionId()
        setInvitationOpen(false)
        setInvitationDetailOpen(false)
    }
    const dateTimePickerConfirmHandler = (payload) => {
        const user = usersToShareWith[0]
        dispatch(putOnWishlist(
            {
                startDate: moment(payload.startDate).toISOString(),
                endDate: moment(payload.endDate).toISOString(),
                id: wishListMissionId, user: user
            }))
        setWishlistMissionId()
        setWishlistOpen(false)
    }
    const dateTimePickerAbortHandler = (id) => {
        setWishlistMissionId()
        setWishlistOpen(false)
    }
    const getRatingNotSwipe = (id) => {
        return {
            content: <NullRevealed label="No!" color="grey" icon={<ThumbDownIcon style={{ fontSize: 80 }} />} position="Right" />,
            actionAnimation: ActionAnimations.REMOVE,
            action: () => dispatch(rateMission({ id: id, rating: "not" }))
        }
    }

    const getRatingHotSwipe = (id) => {
        return {
            content: <NullRevealed label="Hot!!!" color="red" icon={<WhatshotIcon style={{ fontSize: 80 }} />} position="Left" />,
            actionAnimation: ActionAnimations.REMOVE,
            action: () => dispatch(rateMission({ id: id, rating: "hot" }))
        }
    }

    const getWishListSwipe = (id) => {
        return {
            content: <NullRevealed label="Auf die Wishlist" color="red" icon={<RedeemIcon style={{ fontSize: 80 }} />} position="Left" />,
            action: () => putOnWishlistClick(id)
        }
    }
    const getWishListRemoveSwipe = (id) => {
        return {
            content: <NullRevealed label="Von der Liste entfernen" color="grey" icon={<RedeemIcon style={{ fontSize: 80 }} />} position="Right" />,
            actionAnimation: ActionAnimations.REMOVE,
            action: () => removeFromWishlistClick(id)
        }
    }

    const missionList = (missions.map((mission) => {
        let swipeLeft = null
        let swipeRight = null

        if (mode === "view" && mission.state === "PUBLISHED") {
            swipeLeft = (id) => getRatingNotSwipe(id)
            swipeRight = (id) => getRatingHotSwipe(id)
        } else if (mode === "edit" && mission.state === "RATED" && mission.rating === "hot") {
            swipeRight = (id) => getWishListSwipe(id)
        } else if (mode === "wishlist") {
            swipeLeft = (id) => getWishListRemoveSwipe(id)
        }
        return (
            <LazyLoad height={200} key={mission.id} once>
                <SwipeableListItem
                    threshold={0.3}
                    swipeLeft={swipeLeft ? swipeLeft(mission.id) : null}
                    swipeRight={swipeRight ? swipeRight(mission.id) : null}
                >
                    {listMode === "compact" ? (
                        <MissionListItem
                            actions={props.actions}
                            mission={mission}
                            mode={mode}
                            shareMission={shareMission}
                            confirmDelete={confirmDelete}
                            editClickHandler={editMission}
                            detailClickHandler={viewDetailMission}
                            requestRatingClickHandler={requestMissionRating}
                            revokeRequestRatingClickHandler={revokeRequestMissionRating}
                            wishlistClickHandler={putOnWishlistClick}
                            wishlistRemoveHandler={removeFromWishlistClick}
                            invitationClickHandler={invitationClick}
                        ></MissionListItem>
                    ) : (
                            <MissionCard
                                actions={props.actions}
                                mission={mission}
                                mode={mode}
                                shareMission={shareMission}
                                confirmDelete={confirmDelete}
                                editClickHandler={editMission}
                                detailClickHandler={viewDetailMission}
                                requestRatingClickHandler={requestMissionRating}
                                revokeRequestRatingClickHandler={revokeRequestMissionRating}
                                wishlistClickHandler={putOnWishlistClick}
                                wishlistRemoveHandler={removeFromWishlistClick}
                                invitationClickHandler={invitationClick}

                            ></MissionCard>
                        )
                    }
                </SwipeableListItem>
            </LazyLoad>
        )
    }))

    if (missions.length > 0) {
        return (
            <div >
                <MissionListModeSwitcher />
                <SwipeableList >
                    {missionList}
                </SwipeableList>
                <AlertDialog
                    open={dialogOpen}
                    title="Wirklich löschen?"
                    description="Das kann nicht rückgängig gemacht werden. Bist du sicher?"
                    action="Löschen"
                    clickConfirm={deleteMissionConfirmHandler}
                    clickAbort={deleteMissionAbortHandler}
                />
                <MyDateTimePicker
                    open={wishlistOpen}
                    title="Zeitsteuerung"
                    description="Möchtest du ein Zeitfenster bestimmen, wie lange diese Idee auf deiner Wunschliste bleibt?"
                    action="Ab auf die Liste"
                    clickConfirm={dateTimePickerConfirmHandler}
                    clickAbort={dateTimePickerAbortHandler}
                />
                <MyInvitation
                    maxWidth="sm"
                    fullWidth={true}
                    open={invitationOpen}
                    invitation={invitation}
                    title="Einladung versenden"
                    description="Für wann möchtest du eine Einladung senden?"
                    action="Senden"
                    revokeAction="Zurückziehen"
                    clickConfirm={newInvitationConfirmHandler}
                    clickAbort={invitationAbortHandler}
                    clickRevoke={invitationRevokeHandler}
                />
                <MyInvitationDetail
                    maxWidth="sm"
                    fullWidth={true}
                    open={invitationDetailOpen}
                    invitation={invitation}
                    title="Einladung"
                    description="Du hast eine Einladung"
                    confirm="Annehmen"
                    decline="Ablehnen"
                    clickConfirm={invitationConfirmHandler}
                    clickDecline={invitationDeclineHandler}

                    clickAbort={invitationAbortHandler}
                />
            </div>
        )
    } else {
        return (
            <NoContent mode={mode} activeFilterCount={activeFilterCount} />
        )
    }
}



export default (MissionList)
