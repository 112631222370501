import React from 'react'
import { Card, CardContent, Typography, Grid, CardActions } from '@material-ui/core'
import Assets from '../../Assets/Assets'
import Truncate from 'react-truncate';
import { makeStyles } from '@material-ui/core/styles';
import Comments from '../comments/Comments';
import MissionActions from './MissionActions';
import MissionRatingBadge from './MissionRatingBadge';
import MissionWishListTimer from './missionWishlistTimer';
import MissionInvitationTimer from './missionInvitationTimer';


const useStyles = makeStyles((theme) => ({
    missionDescription: { whiteSpace: "pre-line" },
    missionDescriptionCompact: { whiteSpace: "nowrap" },
    missionCard: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        width: "97%",
    },
    hot: {

    },
    not: {
        backgroundColor: "#EFEFEF",
        opacity: "0.5",
    },

    invite : {
        backgroundColor: "#fcf7ff",
        backgroundImage: "url(/static/mask.png)",
        backgroundPosition: "left bottom",
        backgroundSize: "80px",
        backgroundRepeat: "no-repeat",

    },
    spacer: {
        margin: "8px 0 0 0",
    }
}))

const MissionListItem = (props) => {
    const classes = useStyles()
    const mission = props.mission


    const content = (
        <>
            <Typography
                className={classes.missionDescriptionCompact}

            >
                <Truncate lines={4}>
                    {mission.missionDescription}
                </Truncate>
            </Typography>

            <Comments
                onlyLast
                missionId={mission.id}
            />
        </>
    );

    const avatarAssets = (mission.assets.length > 0) ? (
        <Grid container direction="row">
            <Grid item xs={3}>
                <Assets limit avatar files={mission.assets} width="75" height="75" ></Assets>
            </Grid>
            <Grid item xs={9}>
                {content}
            </Grid>
        </Grid>
    ) : (
            <Grid container direction="row">
                <Grid item xs={12}>
                    {content}
                </Grid>
            </Grid>

        )

    return (

        <Card key={mission.id} className={` ${classes[mission.state.toLowerCase()]} ${classes.missionCard} ${classes[mission.rating]}`} >
            <MissionRatingBadge mission={mission} />
            <CardContent onClick={() => props.detailClickHandler(mission.id)}>
                <MissionWishListTimer
                    startDate={mission.shareStart}
                    endDate={mission.shareEnd}
                    state={mission.state}
                />
             <MissionInvitationTimer
                    startDate={mission.invitation ? mission.invitation.startDate : null}
                    state={mission.state}
                />
                <Typography variant="h5" gutterBottom>{mission.missionTitle}</Typography>
                <div >
                    {avatarAssets}
                </div>

            </CardContent>
            <CardActions>
                <MissionActions {...props} />
            </CardActions>

        </Card >
    )

}

export default MissionListItem