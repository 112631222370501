import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectFilteredMissions } from './missionsSlice'
import { onSetPage } from '../filters/filtersSlice'
import MissionList from './MissionList'
import { Typography } from '@material-ui/core';

const MissionShared = (props) => {
    const dispatch = useDispatch()
    const missions = useSelector(selectFilteredMissions)

    useEffect(() => {
        console.log('dispatch Effect')
        dispatch(onSetPage({ page: "missionShared" }))
    }, [dispatch])

    return (
        <>
            <Typography variant="h4">Spielwiese</Typography>
            <Typography variant="subtitle1" paragraph>
                Hier findest du alle Ideen, die ich gerade echt heiß finde.
                Also, falls du mich überraschen möchtest...
            </Typography>

            <MissionList
                missions={missions}
            />
        </>
    )
}


export default (MissionShared)
