import React, { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { editMission, selectMissionById, onSetLoadingState } from './missionsSlice';
import { onSetPage } from '../filters/filtersSlice'

import MissionEditor from './MissionEditor'


const MissionEdit = (props) => {

    const { id: missionId } = useParams()
    let mission = {
        id: "",
        type: "Mission",
        missionTitle: "",
        missionDescription: "",
        preparationTime: "UPTO30",
        duration: "UPTO30",
        sharedWith: [],
        categories: [],
        assets: [],
    };
    mission = useSelector((state) =>
        selectMissionById(state, missionId)
    )

    const dispatch = useDispatch()
    const routerHistory = useHistory()

    useEffect(() => {
        dispatch(onSetPage({ page: "missionEdit" }))
    })

    const cancelCreation = () => {
        routerHistory.goBack()
    }


    const saveEdit = async (data) => {
        //e.preventDefault()
        dispatch(onSetLoadingState({
            loading: true,
            message: "Saving ..."
        }))

        const mission = {
            id: data.id,
            type: data.type,
            missionTitle: data.missionTitle,
            missionDescription: data.missionDescription,
            sharedWith: data.sharedWith,
            preparationTime: data.preparationTime,
            duration: data.duration,
            categories: data.categories,
            assets: data.assets
        }
        dispatch(editMission(mission)).then(() => {
            dispatch(onSetLoadingState({
                loading: false,
                message: "Loading ..."
            }))
            routerHistory.goBack()
        })

    }



    return (
        <>
            <MissionEditor
                pageTitle="Bearbeiten"
                mission={mission}
                cancelCreation={cancelCreation}
                saveEdit={saveEdit}
            />
        </>
    )


}
export default MissionEdit
