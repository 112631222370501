import React from 'react';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'

const Image = (props) => {
  const deleter = (props.editable) ? (<div style={{position:"absolute",left: "50%",top: "95%",  transform: "translate(-50%,-50%)"}}>
    <Fab 
     size="small"
    onClick={props.onDelete}
    color="secondary" >
      <DeleteIcon/>
    </Fab>
   </div>) : null

  if (props.height && props.width) {
    return (
      <div style={{
        height: props.height,
        width: props.width,
        position: "relative",
        overflow: "hidden"
      }}>
        <img style={{ transform: "translateY(-25%)" }} width={props.width} src={props.src} alt="" />
        {deleter}
      </div>
    )

  } else {
    return (
      <div style={{ position: "relative" }}>
        <img height={props.height} src={props.src} alt=""/>
        {deleter}
      </div>
    )
  }


}

export default Image;
