import React from 'react'
import moment from 'moment'
import 'moment-timezone';
import Moment from 'react-moment';
import { Grid, Typography } from '@material-ui/core';

const MissionWishListTimer = (props) => {
    var a = moment();
    var b = moment(props.startDate);
    var c = moment(props.endDate)
    const startText = b.diff(a) > 0 ? (<Typography variant="overline">Start <Moment fromNow locale="de">{props.startDate}</Moment></Typography>) : null
    const endText = props.state === "SHARED" ? (<Typography variant="overline">Ende <Moment fromNow locale="de">{props.endDate}</Moment></Typography>) : null

    return (
        <Grid container direction="row" justify="space-between">
            <Grid item>{startText} </Grid>
            <Grid item>{endText}</Grid>
        </Grid>

    )
}
export default MissionWishListTimer