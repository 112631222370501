import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onSetLoadingState } from './missionsSlice';
import { Storage } from 'aws-amplify'
import CategoryChips from '../categories/CategoryChips'
import { Button, TextField, Typography, Paper, Grid, Snackbar } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import { PreparationTime, Duration } from './MissionInputs'
import Assets from '../../Assets/Assets';
import MissionShare from './MissionShare';
import ScrollToTopOnMount from '../../app/ScrollToTopOnMount';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: "8px",
        padding: "8px"
    },
    spacer: {
        margin: "4px 0 24px 0"
    }

}))

const MissionEditor = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [files, setFiles] = useState([]);
    const [mission, setMission] = useState(props.mission);

    const handleNewAssets = (files) => {
        setFiles(prevState => (
            [...files]
        ))
    }


    const categoryClickHandler = (categoryName) => {
        setMission(prevState => {
            let selected = [...prevState.categories]
            if (selected.find((c) => c === categoryName)) {
                const index = selected.findIndex((c) => c === categoryName);
                selected.splice(index, 1);
            } else {
                selected.push(categoryName)
            }
            return ({
                ...prevState,
                categories: selected
            })
        }
        )
    }
    const sharedWithHandler = (payload) => {
        const sharedWith = mission.sharedWith.indexOf(payload.shareWith) < 0 ? [...mission.sharedWith, payload.shareWith] : []
        setMission(prevState => ({
            ...prevState,
            sharedWith: sharedWith
        }))

    }
    const missionChanged = (e, what) => {
        setMission(prevState => ({
            ...prevState,
            [what]: e.target.value
        }))
    }

    const deleteFile = (data) => {
        const newAssets = mission.assets.filter((asset) => asset.name !== data)
        setMission(prevState => ({
            ...prevState,
            assets: newAssets
        }))

    }
    const uploadFiles = async (files, successCallback) => {
        dispatch(onSetLoadingState({
            loading: true,
            message: "Uploading files ..."
        }))
        const uploadingFiles = files.map((file => {
            return {
                name: mission.id + "_" + file.name,
                path: file.path,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate
            }
        }))
        return Promise.all(files.map(file => {
            return Storage.put(mission.id + "_" + file.name, file)
        })).then((data) => {
            let newMission = {}
            dispatch(onSetLoadingState({
                loading: false,
                message: "Upload done"
            }))
            setMission(prevState => {
                newMission = {
                    ...prevState,
                    assets: [...prevState.assets, ...uploadingFiles]
                }

                return {
                    ...prevState,
                    assets: [...prevState.assets, ...uploadingFiles]
                }
            })
            successCallback(newMission)

        })


    }
    const prepareMissionData = (data) => {
        props.saveEdit(data)

    }
    const save = async () => {
        await uploadFiles(files, prepareMissionData)

    }

    return (
        <Paper className={classes.root}>
            <ScrollToTopOnMount />
            <Typography variant="h4">{props.pageTitle}</Typography>

            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <TextField
                        id="missionTtitle"
                        label="Titel"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        rows="2"
                        onChange={(event) => missionChanged(event, "missionTitle")}
                        defaultValue={mission.missionTitle}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={1} justify="space-around">
                        <Grid item xs={6}>
                            <PreparationTime
                                preparationTime={mission.preparationTime}
                                change={missionChanged}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Duration
                                duration={mission.duration}
                                change={missionChanged}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Passende Stichworte</Typography>
                    <CategoryChips
                        mode="interactive"
                        allowNew={true}
                        selectedCategories={mission.categories}
                        categoryClick={categoryClickHandler}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="missionDescription"
                        label="Beschreibung"
                        multiline
                        fullWidth
                        margin="normal"
                        rows="20"
                        variant="outlined"
                        onChange={(event) => missionChanged(event, "missionDescription")}
                        defaultValue={mission.missionDescription}
                    >
                    </TextField>
                </Grid>
                <Grid item className={classes.spacer}>
                    <DropzoneArea
                        onChange={handleNewAssets}
                        dropzoneClass={classes.dropZone}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/webp', 'image/gif', "video/mpeg", "video/mp4", "video/quicktime", "video/webm", "video/3gpp"]}
                        showPreviews={false}
                        filesLimit={6}
                        maxFileSize={20000000}
                    />
                    <Assets onDelete={deleteFile} editable={true} files={mission.assets} height={100} />
                </Grid>
                <Grid item className={classes.spacer}>
                    <Grid container direction="row" spacing={1} justify="flex-end">
                        <Grid item>
                            
                        </Grid>
                        <Grid item>
                            <Button onClick={props.cancelCreation}>Abbrechen</Button>
                        </Grid><Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={save}
                                startIcon={<SaveIcon />}
                            >
                                Speichern
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <MuiAlert onClose={() => setSnackbarOpen(false)} severity="error">
                    This is a success message!
  </MuiAlert>
            </Snackbar>
        </Paper>
    )


}
export default MissionEditor
