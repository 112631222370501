import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeListMode, getListMode } from '../users/usersSlice'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


const MissionListModeSwitcher = () => {
    const listMode = useSelector(getListMode)
    const dispatch = useDispatch()
    return (
        <ButtonGroup color="secondary" variant="text" style={{ margin: "4px", display: "flex", justifyContent: "flex-end" }}>
        <Button
            variant={listMode === "compact" ? "text" : "text"}
            color={listMode === "compact" ? "primary" : "default"}
            onClick={() => { dispatch(changeListMode('compact')) }}
        >Kompakt</Button>
        <Button
            variant={listMode === "normal" ? "text" : "text"}
            color={listMode === "normal" ? "primary" : "default"}
            onClick={() => { dispatch(changeListMode('normal')) }}
        >Normal</Button>

    </ButtonGroup>
    )
}

export default MissionListModeSwitcher
