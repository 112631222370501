/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:126d3746-ad65-428c-80b3-6c68496b969f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_N47IkKUPA",
    "aws_user_pools_web_client_id": "7ef5u8pkm22dc8qn2srsv5j12j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ttyyji5epfbxbcfn2367dlrmhu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "mipf925a5ff64064a21bb9bb22bdc758a3a112749-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamo17f023e8-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_mobile_analytics_app_id": "39dfd82e4a1d4d5c886aa75c8cc522c8",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
