import React, { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import {
    sendInvitation
} from './missionsSlice'

const InvitationAction = (props) => {
    const action = props.action
    const { id: missionId } = useParams()
    const dispatch = useDispatch()
    const routerHistory = useHistory()
    useEffect(() => {
        switch (action) {
            case "accept":
                dispatch(sendInvitation({
                    invitationMissionId: missionId,
                    state: "ACCEPTED",

                }))
                break

            case "decline":
                dispatch(sendInvitation({
                    invitationMissionId: missionId,
                    state: "DECLINED",

                }))
                break
        }

    })



    return (
        <>
            Updating Invitation
        </>
    )

}

export default InvitationAction