import React  from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/de'


const MyDateTimePicker = (props) => {
    const [startDate, setStartDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = React.useState(moment().endOf("week").format("YYYY-MM-DDTHH:mm"));

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.clickAbortHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                    <form noValidate>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <Grid container direction="row">
                                <Grid item>
                                    <DateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Von"

                                        value={startDate}
                                        ampm={false}
                                        disablePast={true}
                                        onChange={handleStartDateChange}

                                    />
                                </Grid>
                                <Grid item>

                                    <DateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Bis"

                                        value={endDate}
                                        ampm={false}
                                        disablePast={true}
                                        onChange={handleEndDateChange}

                                    />

                                </Grid>

                            </Grid>
                        </MuiPickersUtilsProvider>


                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.clickAbort} color="primary">
                        Abbrechen
          </Button>
                    <Button onClick={() => { props.clickConfirm({ startDate: startDate, endDate: endDate }) }} color="primary" autoFocus>
                        {props.action}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default MyDateTimePicker