import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectFilteredMissions } from './missionsSlice'
import { onSetPage, modeSet } from '../filters/filtersSlice'
import MissionList from './MissionList'

import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { Typography } from '@material-ui/core';

const MissionInbox = (props) => {
    const dispatch = useDispatch()
    const missions = useSelector(selectFilteredMissions)

    useEffect(() => {
        dispatch(onSetPage({ page: "missionInbox" }))
        dispatch(modeSet({ mode: "view" }))
    }, [dispatch])

    

    return (
        <>
            <Typography variant="h4">Deine Inbox</Typography>
            <Typography variant="subtitle1" paragraph>Bitte bewerte diese Naughty Little Things</Typography>

            <MissionList
                swipeable
                missions={missions}

            />
        </>
    )




}



export default (MissionInbox)
