import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { API, Storage, graphqlOperation } from 'aws-amplify'
import Analytics from '@aws-amplify/analytics';
import { getMissionsByCreationDate } from '../../graphql/customQueries'
import {
    createInvitation,
    createLog,
    deleteLog,
    createMission,
    createComment,
    deleteMission,
    updateMission,
    updateInvitation
} from '../../graphql/mutations'
import { getByActionAndId } from '../../graphql/queries'

import { Hub } from 'aws-amplify';

Hub.listen("UserAction", async (data) => {
    const log = {
        action: data.payload.message,
        missionId: data.payload.payload.id,
        missionTitle: data.payload.payload.missionTitle,

    }
    await API.graphql(graphqlOperation(createLog, { input: log }));

})
Hub.listen("UserUpdate", async (data) => {
    let queryParams = {}
    const action = data.payload.message
    if (action === "REVOKEDREQUEST") {
        queryParams = {
            action: { eq: "REQUESTRATING" },
            missionId: data.payload.payload.id
        }
        const foundMission = await API.graphql(graphqlOperation(getByActionAndId, queryParams));
        if (foundMission.data.getByActionAndId.items.length > 0) {
            foundMission.data.getByActionAndId.items.map(async (logItem) => {
                await API.graphql(graphqlOperation(deleteLog, { input: { id: logItem.id } }));
            })
        } else {
            const log = {
                action: data.payload.message,
                missionId: data.payload.payload.id,
                missionTitle: data.payload.payload.missionTitle,

            }
            await API.graphql(graphqlOperation(createLog, { input: log }));
        }
    } else if (action === "WISHLISTREMOVE") {
        queryParams = {
            action: { eq: "WISHLIST" },
            missionId: data.payload.payload.id
        }
        const foundMission = await API.graphql(graphqlOperation(getByActionAndId, queryParams));
        if (foundMission.data.getByActionAndId.items.length > 0) {
            foundMission.data.getByActionAndId.items.map(async (logItem) => {
                await API.graphql(graphqlOperation(deleteLog, { input: { id: logItem.id } }));
            })
        } else {
            const log = {
                action: data.payload.message,
                missionId: data.payload.payload.id,
                missionTitle: data.payload.payload.missionTitle,

            }
            await API.graphql(graphqlOperation(createLog, { input: log }));
        }
    } else if (action === "INVITATIONREVOKED") {
        queryParams = {
            action: { eq: "INVITATION" },
            missionId: data.payload.payload.id
        }
        const foundMission = await API.graphql(graphqlOperation(getByActionAndId, queryParams));
        if (foundMission.data.getByActionAndId.items.length > 0) {
            foundMission.data.getByActionAndId.items.map(async (logItem) => {
                await API.graphql(graphqlOperation(deleteLog, { input: { id: logItem.id } }));
            })
        } else {
            const log = {
                action: data.payload.message,
                missionId: data.payload.payload.id,
                missionTitle: data.payload.payload.missionTitle,

            }
            await API.graphql(graphqlOperation(createLog, { input: log }));
        }
    }



})

const missionsAdapter = createEntityAdapter()

const initialState = missionsAdapter.getInitialState({
    loadingState: {
        loading: false,
        message: "Loading...",
    },
    reloadTimer: 1,
    missions: [],
})

export const fetchMissions = createAsyncThunk(
    'missions/fetch',
    async (_, { getState, rejectWithValue }) => {
        const queryParams = { type: "Mission", sortDirection: "DESC" }
        try {
            const result = await API.graphql(graphqlOperation(getMissionsByCreationDate, queryParams));
            return result.data.getMissionsByCreationDate.items
        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
export const saveNewMission = createAsyncThunk(
    'mission/new',
    async (mission, { getState, rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(createMission, { input: mission }));
            return result.data.createMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                console.log(error, mission)
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const addMissionComment = createAsyncThunk(
    'mission/comment',
    async (comment, { getState, rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(createComment, { input: comment }));
            return result.data.createComment

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const editMission = createAsyncThunk(
    'mission/edit',
    async (mission, { getState, rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(updateMission, { input: mission }));
            return result.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const removeMission = createAsyncThunk(
    'mission/delete',
    async (id, { getState, rejectWithValue }) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteMission, { input: { id: id } }));
            //const result = {}
            // cleanup storage
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, id)

            Promise.all(mission.assets.map(asset => {
                return Storage.remove(asset.name)
            })).then((data) => {

            })
            return result.data.deleteMission

        } catch (err) {
            console.log(err)
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)


export const rateMission = createAsyncThunk(
    'mission/rate',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const state = getState()
            const { id, rating } = missionData
            const mission = state.missions.entities[id]
            if (mission) {
                const response = await API.graphql(graphqlOperation(updateMission, {
                    input: {
                        id: id,
                        rating: rating,
                        state: "RATED"

                    }
                }));
                Analytics.record({
                    name: 'MissionRated',
                    // Attribute values must be strings
                    attributes: { "rating": rating }
                });
                return response.data.updateMission
            }

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
export const sendInvitation = createAsyncThunk(
    'mission/invitationSend',
    async (payload, { getState, rejectWithValue }) => {
        try {
            const sharedAt = new Date().toISOString()
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, payload.invitationMissionId)
            console.log('missionSlice mission', mission)
            const eventName = "InvitationSend" + payload.state
            let missionState = "INVITE"
            const shareWith = (payload.shareWith) ? [payload.shareWith] : mission.sharedWith
            delete payload.shareWith

            let invitation
            let myInvitation
            if (payload.id) {
                if (payload.state === "DECLINED" || payload.state === "REVOKED") {
                    if (mission.rating === "hot" || mission.rating === "not") missionState = "RATED"
                    else missionState = "DRAFT"
                }
                delete payload.mission
                invitation = await API.graphql(graphqlOperation(updateInvitation, {
                    input: {
                        ...payload
                    }
                }))
                myInvitation = invitation.data.updateInvitation
            } else {
                invitation = await API.graphql(graphqlOperation(createInvitation, {
                    input: payload
                }))
                myInvitation = invitation.data.createInvitation
            }
            const invitationId = (payload.state !== "DECLINED" && payload.state !== "REVOKED") ? myInvitation.id : ""
            let updatedMission

            updatedMission = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: payload.invitationMissionId,
                    missionInvitationId: invitationId,
                    sharedWith: shareWith,
                    sharedAt: sharedAt,
                    state: missionState,
                }
            }));
            updatedMission = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: payload.invitationMissionId,
                    rating: (payload.state === "ACCEPTED") ? "hot" : (mission.rating !== null && mission.rating !== "") ? mission.rating : ""
                }
            }));
            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": payload.shareWith, "sharedAt": sharedAt }
            });

            return myInvitation

        } catch (err) {
            let error = err // cast the error for access
            console.log(err)
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
export const shareMissionWith = createAsyncThunk(
    'mission/shareWith',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, missionData.id)

            const sharedWith = mission.sharedWith.indexOf(missionData.shareWith) < 0 ? [...mission.sharedWith, missionData.shareWith] : []
            const eventName = sharedWith.length > 0 ? "MissionShared" : "MissionUnshared"
            const sharedAt = new Date()
            const response = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: missionData.id,
                    updatedAt: mission.updatedAt,
                    sharedWith: sharedWith,
                    sharedAt: sharedAt
                }
            }));
            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": missionData.shareWith, "sharedAt": sharedAt }
            });
            return response.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)

export const requestRating = createAsyncThunk(
    'mission/requestRating',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, missionData.id)

            const sharedWith = [...mission.sharedWith, missionData.user]
            const eventName = "MissionShared"
            const sharedAt = new Date()
            const response = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: missionData.id,
                    updatedAt: mission.updatedAt,
                    sharedWith: sharedWith,
                    sharedAt: sharedAt,
                    state: "PUBLISHED"
                }
            }));

            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": missionData.shareWith, "sharedAt": sharedAt }
            });
            return response.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }

)

export const revokeRequestRating = createAsyncThunk(
    'mission/revokeRequestRating',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, missionData.id)

            const sharedWith = []
            const eventName = "MissionUnshared"
            const sharedAt = new Date()
            const response = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: missionData.id,
                    updatedAt: mission.updatedAt,
                    sharedWith: sharedWith,
                    sharedAt: sharedAt,
                    state: "DRAFT"
                }
            }));

            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": missionData.shareWith, "sharedAt": sharedAt }
            });
            return response.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
export const putOnWishlist = createAsyncThunk(
    'mission/putOnWishlist',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, missionData.id)

            const sharedWith = [...mission.sharedWith, missionData.user]
            const eventName = "MissionOnWishlist"
            const sharedAt = new Date()
            const response = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: missionData.id,
                    updatedAt: mission.updatedAt,
                    sharedWith: sharedWith,
                    sharedAt: sharedAt,
                    state: "SHARED",
                    shareStart: missionData.startDate,
                    shareEnd: missionData.endDate

                }
            }));

            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": missionData.shareWith, "sharedAt": sharedAt }
            });
            return response.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
export const removeFromWishlist = createAsyncThunk(
    'mission/removeFromWishlist',
    async (missionData, { getState, rejectWithValue }) => {
        try {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(getState().missions, missionData.id)

            const sharedWith = []
            const eventName = "MissionRemovedFromWishlist"
            const sharedAt = new Date()
            const response = await API.graphql(graphqlOperation(updateMission, {
                input: {
                    id: missionData.id,
                    updatedAt: mission.updatedAt,
                    sharedWith: sharedWith,
                    sharedAt: sharedAt,
                    shareStart: null,
                    shareEnd: null,
                    missionInvitationId: null,
                    state:  (mission.rating !== null && mission.rating !== "") ? "RATED" : "DRAFT"
                }
            }));

            Analytics.record({
                name: eventName,
                // Attribute values must be strings
                attributes: { "sharedWith": missionData.shareWith, "sharedAt": sharedAt }
            });
            return response.data.updateMission

        } catch (err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(error.response.data)
        }
    }
)
const missionsSlice = createSlice({
    name: 'missions',
    initialState,
    reducers: {
        setReloadTimer(state, action) {
            state.reloadTimer = action.payload
        },
        onSetLoadingState(state, action) {
            state.loadingState.loading = action.payload.loading
            state.loadingState.message = action.payload.message
        },

        onUpdateMissions(state, action) {
            missionsAdapter.setAll(state, action.payload.missions)
            state.status = 'idle'
        },

        onUpdateMission(state, action) {
            missionsAdapter.upsertOne(state, action.payload)
        },
        onNewMission(state, action) {
            missionsAdapter.addOne(state, action.payload)
        },
        onDeleteMission(state, action) {
            missionsAdapter.removeOne(state, action.payload.id)
        },
        onInsertComment(state, action) {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(state, action.payload.mission.id)
            const comment = {
                id: action.payload.id,
                content: action.payload.content,
                owner: action.payload.owner,
                createdAt: action.payload.createdAt,
            }
            if (!mission.comments.items.find((c) => c.id === action.payload.id)) {
                const updatedCommentItems = [...mission.comments.items, comment]
                missionsAdapter.updateOne(state, { id: action.payload.mission.id, changes: { comments: { items: updatedCommentItems } } })
            }
        },
        getMode(state, action) {
            return state.mode
        },
        setMode(state, action) {
            state.mode = action.payload.mode
        },
    },
    extraReducers: (builder) => {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators

        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(rateMission.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            Hub.dispatch('UserAction', {
                message: 'RATED' + payload.rating, payload
            })

        })
        builder.addCase(rateMission.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(addMissionComment.fulfilled, (state, { payload }) => {
            const selectors = missionsAdapter.getSelectors()
            const mission = selectors.selectById(state, payload.mission.id)
            const comment = {
                id: payload.id,
                content: payload.content,
                owner: payload.owner,
                createdAt: payload.createdAt,
            }

            const updatedCommentItems = [...mission.comments.items, comment]
            missionsAdapter.updateOne(state, { id: payload.mission.id, changes: { comments: { items: updatedCommentItems } } })
            if (payload.mission.state === "PUBLISHED" || payload.mission.state === "SHARED")
                Hub.dispatch('UserAction', {
                    message: 'COMMENTED', payload: payload.mission
                })

        })
        builder.addCase(addMissionComment.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(shareMissionWith.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
        })
        builder.addCase(shareMissionWith.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(requestRating.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            Hub.dispatch('UserAction', {
                message: 'REQUESTRATING', payload
            })
        })
        builder.addCase(requestRating.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(revokeRequestRating.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            Hub.dispatch('UserUpdate', {
                message: 'REVOKEDREQUEST', payload
            })
        })
        builder.addCase(revokeRequestRating.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(fetchMissions.pending, (state, { payload }) => {
            state.loadingState.loading = true
        })

        builder.addCase(fetchMissions.fulfilled, (state, { payload }) => {
            missionsAdapter.setAll(state, payload)
            state.loadingState.loading = false
        })
        builder.addCase(fetchMissions.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(saveNewMission.fulfilled, (state, { payload }) => {
            missionsAdapter.addOne(state, payload)

        })
        builder.addCase(saveNewMission.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(editMission.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })

        })
        builder.addCase(editMission.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(removeMission.fulfilled, (state, { payload }) => {
            missionsAdapter.removeOne(state, payload.id)

        })
        builder.addCase(removeMission.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(putOnWishlist.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            Hub.dispatch('UserAction', {
                message: 'WISHLIST', payload
            })


        })
        builder.addCase(putOnWishlist.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(removeFromWishlist.fulfilled, (state, { payload }) => {
            missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            Hub.dispatch('UserUpdate', {
                message: 'WISHLISTREMOVE', payload
            })

        })
        builder.addCase(removeFromWishlist.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })

        /*-------------------------------------------------------------------------------------------------------------------*/
        /*
        /*-------------------------------------------------------------------------------------------------------------------*/
        builder.addCase(sendInvitation.fulfilled, (state, { payload }) => {
            //missionsAdapter.updateOne(state, { id: payload.id, changes: payload })
            console.log('INVITE LOG 2', payload)
            switch (payload.state) {
                case "PENDING":
                    Hub.dispatch('UserAction', {
                        message: 'INVITATION',
                        payload: { ...payload.mission }
                    })
                    break
                case "REVOKED":
                    Hub.dispatch('UserUpdate', {
                        message: 'INVITATIONREVOKED',
                        payload: { ...payload.mission }
                    })
                    break
                case "DECLINED":
                    Hub.dispatch('UserAction', {
                        message: 'INVITATIONDECLINED',
                        payload: { ...payload.mission }
                    })
                    break
                case "ACCEPTED":
                    Hub.dispatch('UserAction', {
                        message: 'INVITATIONACCEPTED',
                        payload: { ...payload.mission }
                    })
                    break

            }



        })
        builder.addCase(sendInvitation.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage
            } else {
                state.error = action.error.message
            }
        })
    },
})


export const {
    onSetLoadingState,
    onUpdateMissions,
    onUpdateMission,
    onUpdateMissionRating,
    onNewMission,
    onDeleteMission,
    onInsertComment,
    setMode,
    getMode,
    setReloadTimer
} = missionsSlice.actions


export const {
    selectById: selectMissionById,
    selectIds: selectMissionIds,
    selectEntities: selectMissionEntities,
    selectAll: selectAllMissions,
    selectTotal: selectTotalMissions
} = missionsAdapter.getSelectors(state => state.missions)

export default missionsSlice.reducer



export const selectFilteredMissions = createSelector(
    // First input selector: all missions
    selectAllMissions,
    // Second input selector: all filter values
    (state) => state.filters,
    (_, mode) => mode,
    // Output selector: receives both values
    (missions, filters, enforcedMode) => {
        const { statusFilter, ratingFilter, categories, categoriesFilterMode, mode, user } = filters
        const useMode = (enforcedMode) ? enforcedMode : mode
        const showAllStatus = (statusFilter === "ALL" || statusFilter === null)
        const showSharedStatus = statusFilter === "SHARED"

        const filteredMissions = missions.filter((mission) => {

            const statusMatches = (showSharedStatus && mission.sharedWith.length > 0)
                || showAllStatus

            const ratingMatches = (ratingFilter === "none" && mission.state === "PUBLISHED")
                || (ratingFilter === "draft" && mission.state === "DRAFT")
                || (ratingFilter && mission.rating === ratingFilter)
                || !ratingFilter

            const categoryMatches = categories.filter((category) => {
                return mission.categories.indexOf(category) >= 0
            })
            const modeMatches = (useMode === "edit" && mission.owner === user && mission.state !== "SHARED" && mission.state !== "INVITE" && ratingMatches)
                || (useMode === "view" && mission.owner !== user && mission.state === "PUBLISHED" && mission.sharedWith.indexOf(user) >= 0)
                || (useMode === "view" && mission.owner !== user && mission.state === "INVITE" && mission.invitation.state === "PENDING"
                    && mission.sharedWith.indexOf(user) >= 0)
                || (useMode === "viewHot" &&
                    mission.owner !== user
                    && mission.state === "SHARED"
                    && mission.sharedWith.indexOf(user) >= 0
                    && mission.rating === "hot"
                    && new Date(mission.shareStart).valueOf() <= new Date().valueOf() + (12 * 60 * 60 * 1000)
                    && new Date(mission.shareEnd).valueOf() >= new Date().valueOf()
                )
                || (useMode === "viewHot" &&
                    mission.owner !== user
                    && mission.state === "INVITE"
                    && mission.sharedWith.indexOf(user) >= 0
                    && mission.rating === "hot"
                    && mission.invitation.state === "ACCEPTED"
                )
                || (useMode === "viewNotHot" && mission.owner !== user && mission.sharedWith.indexOf(user) >= 0 && mission.rating === "not")
                || (useMode === "wishlist" && mission.owner === user && mission.sharedWith.length > 0
                    && (mission.state === "INVITE"
                        && (mission.invitation.state === "PENDING" || mission.invitation.state === "ACCEPTED"))
                )
                || (useMode === "wishlist" && mission.owner === user && mission.sharedWith.length > 0
                    && (mission.state === "SHARED" && mission.rating === "hot")
                )
            return statusMatches && modeMatches && (
                (categoriesFilterMode === "OR" && (categories.length === 0 || categoryMatches.length > 0))
                || (categoriesFilterMode === "AND" && (categories.length === 0 || (categoryMatches.length === categories.length)))
                || (categoriesFilterMode === "NOR" && (categories.length === 0 || (categoryMatches.length === 0)))

            )

        })
        if (useMode === "edit") {
            filteredMissions.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((b.createdAt > a.createdAt) ? 1 : 0));
        } else {
            filteredMissions.sort((a, b) => (a.sharedAt > b.sharedAt) ? -1 : ((b.sharedAt > a.sharedAt) ? 1 : 0));
        }

        return filteredMissions

    }
)

export const getMissionsCount = createSelector(
    (state, mode) => mode,
    (mode) => {
        const missions = selectFilteredMissions("edit");

        return missions.length
    }

)
export const selectSortedComments = createSelector(
    // First input selector: all missions
    selectAllMissions,
    // Second input selector: all filter values
    (_, missionId) => missionId,
    // Output selector: receives both values
    (missions, missionId) => {

        try {
            const mission = missions.find((mission) => mission.id === missionId)
            const comments = mission.comments.items
            const sortedComments = [...comments]
            sortedComments.sort((a, b) => (a.createdAt < b.createdAt) ? -1 : ((b.createdAt < a.createdAt) ? 1 : 0));

            return sortedComments
        }
        catch (err) {
            return []
        }

    })

export const selectMissionIvitation = createSelector(
    selectAllMissions,
    (_, missionId) => missionId,
    (missions, missionId) => {

        try {
            const mission = missions.find((mission) => mission.id === missionId)
            const invitations = mission.invitations.items
            const newestInvitation = invitations.reduce((a, b) => {
                return (a.createdAt >= b.createdAt ? a : b)
            });

            return newestInvitation
        }
        catch (err) {
            return {}
        }
    }
)

export const selectRandomMission = createSelector(
    selectFilteredMissions,
    (missions) => {
        try {
            return missions[Math.floor(Math.random() * missions.length)];

        } catch (err) {
            return null
        }
    }

)
export const selectReloadTimer = createSelector(
    (state) => state.missions.reloadTimer,
    (reloadTimer) => {
        try {
            return reloadTimer;

        } catch (err) {
            return null
        }
    }

)