import { combineReducers } from '@reduxjs/toolkit'

import missionsReducer from '../features/missions/missionsSlice'
import filtersReducer from '../features/filters/filtersSlice'
import categoriesReducer from '../features/categories/categoriesSlice'
import usersReducer from '../features/users/usersSlice'


const rootReducer = combineReducers({
    missions: missionsReducer,
    filters: filtersReducer,
    categories: categoriesReducer,
    users: usersReducer,
})

export const RootState = rootReducer

export default rootReducer