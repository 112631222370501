/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMission = /* GraphQL */ `
  query GetMission($id: ID!) {
    getMission(id: $id) {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const listMissions = /* GraphQL */ `
  query ListMissions(
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        content
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastAction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        missionId
        missionTitle
        action
        payload
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMissionsByCreationDate = /* GraphQL */ `
  query GetMissionsByCreationDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMissionsByCreationDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      nextToken
    }
  }
`;
export const getByActionAndId = /* GraphQL */ `
  query GetByActionAndId(
    $missionId: String
    $action: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getByActionAndId(
      missionId: $missionId
      action: $action
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        missionId
        missionTitle
        action
        payload
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
