import React from 'react'
import { Select,  InputLabel } from '@material-ui/core';

export const PreparationTime = (props) => {

    return (
        <div>
            <InputLabel htmlFor="preperationTime" id="preperationTime-label">Vorbereitungszeit</InputLabel>
            <Select
                labelId="preperationTime-label"
                native
                value={props.preparationTime}
                onChange={(e) => {props.change(e, "preparationTime")}}
                inputProps={{
                    name: 'preparationTime',
                    id: 'preparationTime',
                }}
            >
                <option value="UPTO15">Kaum Vorbereitung</option>
                <option value="UPTO30">Maximal 30 Minuten</option>
                <option value="UPTO60">Ca. 1 Stunde</option>
                <option value="MORETHAN60">Viel Vorbereitung, > 1 Stunde</option>
            </Select>
        </div>
    )
}

export const Duration = (props) => {

    return (
        <div>
            <InputLabel htmlFor="duration" id="duration-label">Dauer</InputLabel>
            <Select
                labelId="duration-label"
                native
                value={props.duration}
                onChange={(e) => {props.change(e, "duration")}}
                inputProps={{
                    name: 'duration',
                    id: 'duration',
                }}
            >
                <option value="UPTO15">Echter Quickie, maximal 15 Minuten</option>
                <option value="UPTO30">Maximal 30 Minuten</option>
                <option value="UPTO60">Ca. 1 Stunde</option>
                <option value="MORETHAN60">Mindestens 1 Stunde</option>
            </Select>
        </div>
    )
}