import React from 'react';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import { Player, ControlBar } from 'video-react';

import "video-react/dist/video-react.css"; // import css

const Video = (props) => {

  const deleter = (props.editable) ? (<div style={{position:"absolute",left: "50%",top: "95%",  transform: "translate(-50%,-50%)"}}>
  <Fab 
   size="small"
  onClick={props.onDelete}
  color="secondary" >
    <DeleteIcon/>
  </Fab>
 </div>) : null
  if (props.height && props.width) {
    return (
      <div style={{
        maxHeight: props.height,
        width: props.width,
        overflow: "hidden"
      }}>
       <div style={{transform: "translateY(-25%)"}}>
        <Player
          loop={true}
          fluid={true}
          playsInline
          autoPlay
          muted
          src={props.url}
          >
          <ControlBar disableCompletely={true} />
        </Player>
        </div>
      </div>
    )

  } else {
    return (
      <div style={{marginTop: props.marginTop, position: "relative"}}>
      <Player
          loop={true}
          height={props.height}
          fluid={props.fluid? true : false}
          playsInline
          autoPlay
          muted
          src={props.url}
        >
          <ControlBar disableCompletely={true} />
        </Player>
        {deleter}
        </div>
    )
  }

}

export default Video;
