import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
     palette: {
        primary: {
            main: "#ff4081",
            light:"#ff79b0",
            dark:"#c60055",
            disabled: "#EFEFEF",
            contrastText:"#ffffff"
        },
        secondary: {
            main: "#ad1457",
            light:"#e35183",
            dark:"#78002e",
            contrastText:"#ffffff"
        }
    }
})