import React from 'react'
import { IconButton, Badge, Grid, Typography } from '@material-ui/core';


const MyBottomNavigationAction = (props) => {
    return (

        <Grid container 
            direction="column"
            justify="flex-start"
            alignItems="center">
            <Grid item>
                <IconButton
                    onClick={props.onClick}
                    color={props.mode === props.target ? "secondary" : "inherit"}>
                    <Badge
                        badgeContent={props.count}
                        color="primary">{props.icon}</Badge>
                </IconButton>
            </Grid>
            <Grid item>
                <Typography variant="caption">{props.label}</Typography>
            </Grid>
        </Grid>
    )
}
export default MyBottomNavigationAction