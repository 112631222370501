import React from 'react'
import { Badge, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../../features/filters/Filter'
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles({
    spacer:{
        margin:'0 0 12px 0',
    },
});

const FilterBar = (props) => {
    const classes = useStyles()
    return (props.visible) ?
        (
            <div>
                <Grid container spacing={0} justify="space-around">
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        badgeContent={props.filterCount}
                        color="primary">
                        <Button
                        className={classes.spacer}
                            color={props.filterBarVisible ? "primary" : "secondary"}
                            variant="contained"
                            onClick={props.filterToggleClick}
                            startIcon={<FilterListIcon />}
                        >Filter
                       </Button></Badge>
                </Grid>
                <Filter />
            </div>)
        : null
}

export default FilterBar