import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectFilteredMissions } from './missionsSlice'
import { onSetPage } from '../filters/filtersSlice'
import MissionList from './MissionList'
import { Typography } from '@material-ui/core';

const MissionWishlist = (props) => {
    const dispatch = useDispatch()
    const missions = useSelector(selectFilteredMissions)
    
    useEffect(() => {
        dispatch(onSetPage({ page: "missionWishlist" }))
    }, [dispatch])


    return (
        <>
            <Typography variant="h4">Deine Wunschliste</Typography>
            <Typography variant="subtitle1" paragraph>
                Alle deine Ideen, die du gerade heiß findest und mit welchen du spontan überrascht werden könntest
            </Typography>

            <MissionList
                missions={missions}
            />
        </>
    )


}



export default (MissionWishlist)
