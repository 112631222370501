import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    navigationBar: {
        padding:"6px"
    },
}))

const MyBottomNavigationBar = (props) => {
    const classes = useStyles()
    const navigation = props.children.map((child, i) => {
        return (
            <Grid item key={"Nav" + i}>{child}</Grid>
        )
    })
    return (
        <Paper elevation={8} className={classes.navigationBar}>
            <Grid container
                spacing={3}
                direction="row"
                justify="space-around">
                {navigation}
            </Grid>
        </Paper>
    )
}
export default MyBottomNavigationBar