import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Whatshot, ContactSupport, ThumbDown } from '@material-ui/icons'


const useStyles = makeStyles((theme) => ({
    
    sharedBadge: {
        position: "absolute",
        backgroundColor: theme.palette.primary.disabled,
        fontSize: "12px",
        transform: "rotate(30deg)",
        right: "-40px",
        top: "10px",
        minWidth: "160px",
        textAlign: "center",
        padding: "1px 40px 1px 40px",
    },
    hot:{
        backgroundColor: theme.palette.primary.main,
        color:"#FFFFFF"
    },
  
    spacer: {
        margin: "8px 0 0 0",
    }
}))

const MissionRatingBadge = (props) => {
    const classes = useStyles()
    const mission = props.mission
    let badge = null;
    if (mission.state === "PUBLISHED") {
        badge = (<div className={`${classes.sharedBadge}`}><ContactSupport /></div>)
    } else if (mission.state === "RATED" && mission.rating==="hot") {
        badge = (<div className={`${classes.sharedBadge} ${classes[mission.rating]}`}><Whatshot /></div>)
    } else if (mission.state === "RATED" && mission.rating==="not") {
        badge = (<div className={`${classes.sharedBadge} ${classes[mission.rating]}`}><ThumbDown /></div>)
    } 
    return badge

}
export default MissionRatingBadge