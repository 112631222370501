import React, { useState } from 'react'
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    missionDescription: { whiteSpace: "pre-line" },
    missionDescriptionCompact: { whiteSpace: "nowrap" },
    missionCard: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
    },
    media: {
        width: "100%",
        height: "420px"
    }

}))
const NoContent = ({ mode, activeFilterCount }) => {
    const classes = useStyles()
    const images = [
        { file: "/static/empty_1.jpg" },
        { file: "/static/empty_2.jpg" },
        { file: "/static/empty_3.jpg" },
        { file: "/static/empty_4.jpg" },
        { file: "/static/empty_5.jpg" },
        { file: "/static/empty_6.jpg" },
        { file: "/static/empty_7.jpg" },

    ]
    const [image, setImage] = useState(images[Math.floor(Math.random() * (images.length))])

    const noContent = () => {
        switch (mode) {
            case "viewHot":
                return (
                    <>
                        <Typography variant="h4">Leere Spielwiese?</Typography>
                        <Typography>
                            Offensichtlich gibt es gerade keine heißen Ideen, mit denen du mich überraschen könntest... Schau einfach bald wieder rein!
                    </Typography>
                    </>
                )

            case "view":
                return (
                    <>
                        <Typography variant="h4">Deine Inbox ist leer</Typography>
                        <Typography>Offensichtlich gibt es keine neuen Ideen für dich zur Bewertung...</Typography>
                    </>

                )
            case "edit":
                return (
                    <>
                        <Typography variant="h4">Deine Fantasien</Typography>
                        <Typography>Gar keine heißen Ideen, die du mit mir teilen möchtest? Na los...!</Typography>
                    </>
                )
            case "wishlist":
                return (
                    <>
                        <Typography variant="h4">Deine Wunschliste</Typography>
                        <Typography>Hier packst du alle deine Ideen hinein, mit welchen ich dich spontan überrachen könnte.</Typography>
                    </>
                )
            default:
                return (
                    <Typography></Typography>

                )
        }
    }

    const filterText = (activeFilterCount > 0) ? (
        <Typography>Vielleicht liegt es aber auch an deinen <FilterListIcon />Filtereinstellungen, dass du hier nichts siehts...</Typography>
    ) : null

    const noContentText = noContent()
    return (
        <Card className={classes.missionCard}>
            <CardMedia className={classes.media}
                image={image.file}
            />
            <CardContent className={classes.missionDescription}>
                {noContentText}
                {filterText}
            </CardContent>
        </Card>
    )
}
export default NoContent