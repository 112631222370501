import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import Amplify from 'aws-amplify'
import Analytics from '@aws-amplify/analytics';

import { BrowserRouter } from 'react-router-dom'
import aws_exports from './aws-exports'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './components/Layout/theme';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './app/rootReducer'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Amplify.configure(aws_exports);

Analytics.autoTrack('session', {
    enable: true,
    provider: 'AWSPinpoint'
});

Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    provider: 'AWSPinpoint',
    
});



export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};


const preloadedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState

})

store.subscribe(() => {
  saveState({
    users: store.getState().users,
    filters: store.getState().filters
  });
});


const app = (
  <BrowserRouter>
    <App></App>
  </BrowserRouter>
)
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {app}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

