import React, { useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de'
import { Card, Chip, CardContent, CardHeader, Typography, Button, CardActions, Grid, Divider } from '@material-ui/core'
import Assets from '../../Assets/Assets'
import { makeStyles } from '@material-ui/core/styles';
import MissionRating from './MissionRating';
import {  selectMissionById } from './missionsSlice'
import { onSetPage} from '../filters/filtersSlice'

import Comments from '../comments/Comments';
import '../../app/ScrollToTopOnMount'
import ScrollToTopOnMount from '../../app/ScrollToTopOnMount';

const useStyles = makeStyles((theme) => ({
    missionDescription: { whiteSpace: "pre-line" },
    missionDescriptionCompact: { whiteSpace: "nowrap" },
    missionCard: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        width: "97%",
    },

    sharedBadge: {
        position: "absolute",
        backgroundColor: "#fce4ec",
        transform: "rotate(30deg)",
        right: "-30px",
        top: "10px",
        minWidth: "150px",
        textAlign: "center",
        padding: "2px 40px 2px 40px",
    },
    spacer: {
        margin: "8px 0 8px 0",
    },
    categoryChips:{
        margin: "0 0 12px 0"
    },

}))



const MissionDetail = (props) => {
    const dispatch = useDispatch()
    const routerHistory = useHistory()
    const { id: missionId } = useParams()
    const { mode } = useSelector((state) => state.filters);
    const mission = useSelector((state) => selectMissionById(state, missionId))
    const classes = useStyles()
    useEffect(() => {
        dispatch(onSetPage({ page: "missionDetail" }))
    })

    if (!mission) {
        return null
    }


    const missionDateString = (
        <><Moment format="DD.MM.YYYY HH:mm">{mission.createdAt}</Moment> (Update <Moment fromNow>{mission.updatedAt}</Moment>)</>

    )
    const categoryChips = mission.categories.map((category, i) => {
        return (
            <Chip
                size="small"
                label={category}
                color="primary"
                variant="default"
                key={i} />
        )
    })
    const preparationTimeText = {
        "UPTO15": "Kaum Vorbereitung nötig",
        "UPTO30": "Maximal 30 Minuten",
        "UPTO60": "Ca. 1 Stunde",
        "MORETHAN60": "Viel Vorbereitung, mindestens 1 Stunde"
    }
    const durationText = {
        "UPTO15": "Echter Quickie, maximal 15 Minuten",
        "UPTO30": "Maximal 30 Minuten",
        "UPTO60": "Ca. 1 Stunde",
        "MORETHAN60": "Mindestens 1 Stunde"
    }

    return (
        <>
        <ScrollToTopOnMount/>

            <Card key={mission.id} className={classes.missionCard}>

                <CardHeader title={mission.missionTitle} subheader={missionDateString} />
                <div className={classes.categoryChips}>{categoryChips}</div>
                <Assets carousel files={mission.assets} width="100%" height={100} />
                <CardContent className={classes.cardContent} >
                    <Divider className={classes.spacer} />
                    <Grid container spacing={5}>
                        <Grid item>
                            <Typography variant="h6">Vorbereitungszeit:</Typography><Typography>{preparationTimeText.[mission.preparationTime]}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">Dauer:</Typography><Typography>{durationText.[mission.duration]}</Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.spacer} />
                    <MissionRating mode={mode} missionId={mission.id} rating={mission.rating} />
                    <Typography display="block" className={classes.missionDescription} paragraph
                     onClick={() => { routerHistory.goBack() }}
                     >{mission.missionDescription}</Typography>
                    <Comments
                        missionId={mission.id}
                        allow
                    />
                </CardContent>
                <div className={classes.cardActions}>

                    <CardActions style={{ float: "right" }}>
                        <Button onClick={() => { routerHistory.goBack() }}>Zurück</Button>

                    </CardActions>
                </div>
            </Card>
        </>
    )

}

export default MissionDetail