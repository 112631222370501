import { createSlice, createSelector } from '@reduxjs/toolkit'


export const Users = [
    "Jochen",
    "Rita",
]

const initialState = {
    onboardingSeen: '',
    currentUser: null,
    lastLogin: null,
    listMode: 'compact'
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        onOnboarding(state, action) {
            state.onboardingSeen = action.payload.onboarded
        },
        onUserSet(status, action) {
            status.currentUser = action.payload
            status.lastLogin = new Date().toString()
        },
        changeListMode(status, action) {
            status.listMode = action.payload
        },
    },
})


export const {
    onOnboarding,
    onUserSet,
    changeListMode,
} = usersSlice.actions

export default usersSlice.reducer

export const selectUsersToShareWith = createSelector(
    (state) => state.filters,
    (state) => state.users,
    // Output selector: receives both values
    (filters) => {
        return Users.filter((user) => user !== filters.user)
    })
export const getListMode = createSelector(
    (state) => state.users,
    // Output selector: receives both values
    (user) => {
        return user.listMode
    })
