/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMission = /* GraphQL */ `
  mutation CreateMission(
    $input: CreateMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    createMission(input: $input, condition: $condition) {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const updateMission = /* GraphQL */ `
  mutation UpdateMission(
    $input: UpdateMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    updateMission(input: $input, condition: $condition) {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const deleteMission = /* GraphQL */ `
  mutation DeleteMission(
    $input: DeleteMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    deleteMission(input: $input, condition: $condition) {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
