/* eslint-disable */
// this is an auto generated file. This will be overwritten


export const listMissions = /* GraphQL */ `
  query ListMissions(
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        state
        shareStart
        shareEnd
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        comments {
        items {
          id
          content
          createdAt

        }
        nextToken
      }
      invitation{
          id
          invitationText
          createdAt
          state
          startDate
      }
        owner
      }
      nextToken
    }
  }
`;

export const getMissionsByCreationDate = /* GraphQL */ `
  query GetMissionsByCreationDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMissionsByCreationDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        state
        shareStart
        shareEnd
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        comments {
        items {
          id
          content
          createdAt
          owner
        }
        nextToken
      }
      invitation{
          id
          invitationText
          createdAt
          state
          startDate
      }
        owner
      }
      nextToken
    }
  }
`;
