/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMission = /* GraphQL */ `
  subscription OnCreateMission {
    onCreateMission {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const onUpdateMission = /* GraphQL */ `
  subscription OnUpdateMission {
    onUpdateMission {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const onDeleteMission = /* GraphQL */ `
  subscription OnDeleteMission {
    onDeleteMission {
      id
      type
      missionTitle
      missionDescription
      createdAt
      updatedAt
      sharedAt
      preparationTime
      duration
      sharedWith
      categories
      assets {
        lastModified
        lastModifiedDate
        name
        path
        size
        type
        webkitRelativePath
      }
      rating
      invitation {
        id
        mission {
          id
          type
          missionTitle
          missionDescription
          createdAt
          updatedAt
          sharedAt
          preparationTime
          duration
          sharedWith
          categories
          rating
          shareStart
          shareEnd
          state
          owner
        }
        startDate
        invitationText
        invitationComment
        state
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shareStart
      shareEnd
      state
      owner
    }
  }
`;
export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation {
    onCreateInvitation {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvitation = /* GraphQL */ `
  subscription OnUpdateInvitation {
    onUpdateInvitation {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvitation = /* GraphQL */ `
  subscription OnDeleteInvitation {
    onDeleteInvitation {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      startDate
      invitationText
      invitationComment
      state
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      mission {
        id
        type
        missionTitle
        missionDescription
        createdAt
        updatedAt
        sharedAt
        preparationTime
        duration
        sharedWith
        categories
        assets {
          lastModified
          lastModifiedDate
          name
          path
          size
          type
          webkitRelativePath
        }
        rating
        invitation {
          id
          startDate
          invitationText
          invitationComment
          state
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        shareStart
        shareEnd
        state
        owner
      }
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($owner: String) {
    onCreateCategory(owner: $owner) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($owner: String) {
    onUpdateCategory(owner: $owner) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($owner: String) {
    onDeleteCategory(owner: $owner) {
      id
      categoryName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      lastAction
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLog = /* GraphQL */ `
  subscription OnCreateLog($owner: String) {
    onCreateLog(owner: $owner) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateLog = /* GraphQL */ `
  subscription OnUpdateLog($owner: String) {
    onUpdateLog(owner: $owner) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteLog = /* GraphQL */ `
  subscription OnDeleteLog($owner: String) {
    onDeleteLog(owner: $owner) {
      id
      missionId
      missionTitle
      action
      payload
      createdAt
      updatedAt
      owner
    }
  }
`;
